<template>
  <b-select :value="value" :placeholder="`${$tc('select')} ${$tc('status')}`" @input="onSelect">
    <option key="Pending" value="pending">
{{ $tc('pending') }}
</option>
    <option key="Error" value="error">
{{ $tc('error') }}
</option>
    <option key="Processing" value="processing">
{{ $tc('processing') }}
</option>
    <option key="BuildingReport" value="buildingReport">
{{ $tc('sendInvoiceAndBuildReport') }}
</option>
    <option key="Complete" value="complete">
{{ $tc('complete') }}
</option>
  </b-select>
</template>
<script>
export default {
  props: {
    value: { type: String },
  },
  methods: {
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected)
        this.$emit('set-selected', selected || {})
      }
    },
  },
}
</script>
