<template>
  <b-dropdown :value="value" hoverable aria-role="list" @change="onSelect">
    <button slot="trigger" class="button is-info">
      <span>{{ value }}</span>
      <b-icon pack="fad" icon="caret-down"></b-icon>
    </button>
    <b-dropdown-item v-for="option in options" :key="option" aria-role="listitem" :value="option">
{{ option }}
</b-dropdown-item>
  </b-dropdown>
</template>
<script>
export default {
  props: {
    value: { type: Number },
  },
  computed: {
    options: {
      get(_this) {
        const options = []
        for (let index = 2019; index < new Date().getFullYear() + 1; index++) {
          options.push(index)
        }

        return options
      },
    },
  },
  methods: {
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected)
        this.$emit('set-selected', selected || {})
      }
    },
  },
}
</script>
