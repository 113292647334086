<template>
  <b-select :value="value" :placeholder="`${$tc('status')} DIAN`" @input="onSelect">
    <option key="Todos" value="">
{{ $tc('all') }}
</option>
    <option key="Procesando" value="Procesando">
{{ $tc('processing') }}
</option>
    <option key="Aprobada" value="Aprobada">
{{ $tc('approved') }}
</option>
    <option key="Rechazada" value="Rechazada">
{{ $tc('rejected') }}
</option>
  </b-select>
</template>

<script>
export default {
  props: {
    value: { type: String },
  },
  methods: {
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected)
        this.$emit('set-selected', selected || {})
      }
    },
  },
}
</script>
