import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
export const messages = {
  /*
        __  .__   __.   _______  __       _______     _______.
    |  | |  \ |  |  /  _____||  |     |   ____|   /       |
    |  | |   \|  | |  |  __  |  |     |  |__     |   (----`
    |  | |  . `  | |  | |_ | |  |     |   __|     \   \
    |  | |  |\   | |  |__| | |  `----.|  |____.----)   |
    |__| |__| \__|  \______| |_______||_______|_______/
        */
  en: {
    abbreviation: 'Abreviatura',
    viewTutorial: 'Realizar tutorial',
    accepted: 'Accepted',
    helpResolution: 'Si la resolución solicitada en el portal Muisca no aparece en la parte inferior, debes realizar asociación de prefijos. Para poder asociar prefijos y te aparezca Aliaddo SAS en el portal de facturando electrónicamente (Producción), debes enviar los documentos necesarios para habilitarse con el testSetId, 8 facturas, 1 nota crédito y una 1 débito, válida que en el log de documentos tengas 8 facturas válidas en modo habilitación, 1 nota crédito y 1 nota débito.',
    acceptedByDefault: 'Accepted by default',
    account: 'My account',
    action: 'Action|Actions',
    active: 'Enabled',
    add: 'Add',
    addAttribute: 'Add another attribute',
    addItem: 'Add product',
    addonQuantity: 'Addon quantity',
    address: 'Address|Addresses',
    adjust: 'Adjust',
    adjustment: 'Adjustment',
    agree: 'Agree',
    all: 'All',
    amount: 'Amount',
    amountPayable: 'Total payable',
    amountToCredit: 'Amount to credit',
    amountWithheld: 'Amount withheld',
    and: 'And',
    answer: 'Answer',
    approved: 'Approved',
    attachment: 'Attachment|Attachments',
    attribute: 'Attribute|Attributes',
    attributeValue: 'Attribute value',
    available: 'Available!',
    balance: 'Balance',
    bankCharge: 'Bank charge',
    bankCharges: 'Bank Charges (if any)',
    billing: 'Billing',
    billingInformation: 'Billing information',
    birthday: 'Birthday',
    both: 'Both',
    branch: 'Branch|Branches',
    cancel: 'Cancel',
    canceled: 'Canceled',
    canceledBy: 'Canceled by',
    cancellation: 'Cancellation',
    card: 'Card',
    category: 'Category|Categories',
    cellPhone: 'Mobile',
    change: 'Change',
    changeAddress: 'Change address',
    changePassword: 'Change password',
    chargeLevel: 'Level charge',
    city: 'City|Cities',
    close: 'Close',
    closed: 'Closed',
    code: 'Code',
    codeOptionalAuto:
      'Code (If you do not enter it, an automatic code is entered)',
    codeSupplier: 'Supplier code',
    collectionExpense: 'Collection expense',
    column: 'Column|Columns',
    company: 'Company|Companies',
    companyName: 'Company name',
    complete: 'Complete',
    component: 'Component|Components',
    componentOrIngredient: 'Components o ingredients',
    computerInvoice: 'Computer invoice',
    concept: 'concept',
    confirm: 'Are you sure?',
    confirmationCode: 'Confirmation code',
    consecutive: 'Consecutive',
    consecutiveExternal: 'Consecutive external',
    consecutiveInitial: 'Consecutive initial',
    consignaturePassword: 'Confirm Password',
    contact: 'Contact|Contacts',
    contains: 'Contains',
    contingencyCheckbook: 'Contingency checkbook',
    cost: 'Cost|Costs',
    costAdjustment: 'Cost adjustment',
    costInitial: 'Initial cost',
    country: 'Country|Countries',
    create: 'Create',
    createdAt: 'Created at',
    createdBy: 'Created by',
    creditAvaible: 'Credit avaible',
    creditBalance: 'Credit balance',
    creditNote: 'Credit note|Credit notes',
    creditNoteBill: 'Credit note on pruchase|Credit notes on pruchases',
    creditNoteInvoice: 'Credit note on sale|Credit notes on sales',
    criterion: 'Criterion|Criterions',
    culture: 'Culture',
    currency: 'Currency',
    currentCost: 'Current cost',
    currentPassword: 'Current Password',
    currentQuantity: 'Current quantity',
    customer: 'Customer|Customers',
    customerNote: 'Customer note',
    date: 'Date',
    dateFormat: 'Date format',
    dateRange: 'Rango de fechas',
    day: 'Day|Days',
    debitNote: 'Debit note|Debit notes',
    debitNoteBill: 'Debit note on purchase|Debit notes on purchases',
    debitNoteInvoice: 'Debit note on sale|Debit notes on sales',
    decimal: 'decimal|decimals',
    declined: 'Declined',
    delete: 'Delete',
    signDeleteConfirm:
      '¿Esta seguro de eliminar la firma configurada, si tienes firma propia debes cargar de nuevo la firma?',
    deletedBy: 'Deleted by',
    department: 'Department',
    description: 'Description',
    detail: 'Detail',
    dimension: 'Dimension|Dimensions',
    dimensionItem: 'Dimensions (Length x Width x Heigth)',
    disabled: 'Disabled',
    discount: 'Discount',
    discountLevel: 'Level discount',
    document: 'Document|Documents',
    documentSupport: 'Document support|Documents support',
    documentType: 'Document type',
    download: 'Download',
    dr: 'Dr.',
    draft: 'Draft',
    dueDate: 'Due date',
    duplicate: 'Duplicate',
    edit: 'Edit',
    electronicInvoice: 'Electronic invoice',
    email: 'E-mail',
    emailPersonal: 'Personal e-mail',
    emailTemplate: 'E-mail template|e-mail template',
    emailWork: 'E-mail work',
    enabled: 'Enabled',
    enabledLoyalty: 'Enabled loyalty',
    enabledPortal: 'Allow portal access',
    enabledReview: 'Allow us to rate our service or business',
    enablePos: 'Enable POS',
    end: 'End',
    endDate: 'End date',
    endswith: 'Ends With',
    entity: {
      address: 'Address|Addresses',
      attribute: 'Attribute|Attributes',
      bill: 'Bill|Bills',
      billing: 'Billing',
      branch: 'Branch|Branches',
      branchDocument: 'Document setup|Documents setup',
      brand: 'Brand|Brands',
      category: 'Category|Categories',
      charge: 'Charge|Charges',
      companyPreference: 'Preferences|Preferences',
      contact: 'Contact|Contacts',
      creditNoteBill: 'Credit note on pruchase|Credit notes on pruchases',
      creditNoteInvoice: 'Credit note on sale|Credit notes on sales',
      currency: 'Currency|Currencies',
      customer: 'Customer',
      debitNoteBill: 'Debit note on purchase|Debit notes on purchases',
      debitNoteInvoice: 'Debit note on sale|Debit notes on sales',
      discount: 'Discount|Discounts',
      document: 'Document|Documents',
      emailTemplate: 'E-mail template|E-mail templates',
      home: 'MONOFACTURA|MONOFACTURA',
      internalServerError: 'Se ha producido un error realizando la petición',
      inventory: 'Inventory',
      invoice: 'Invoice|Invoices',
      invoicePurchase: 'Purchase invoice|Purchase invoices',
      invoiceSale: 'Invoice|Invoices',
      invoiceSaleComputer:
        'Invoice of sale traditional|Invoices of sale traditional',
      item: 'Product|Products',
      itemPriceList: 'Prices by list',
      login: 'Login|Login',
      Main: 'Main|Main',
      measuringUnit: 'Unit of measure|Units of measure',
      myAccount: 'My account|My accounts',
      payment: 'Payment|Payments',
      paymentMeans: 'Payment mean|Payment means',
      person: 'Person|People',
      portal: 'portal',
      pos: 'Point of sale|Points of sale',
      prepayment: 'Prepayment|Prepayments',
      price: 'Price|Prices',
      priceList: 'Price list|Price lists',
      product: 'Product|Products',
      purchaseOrder: 'Purchase order|Purchase orders',
      quote: 'Quote|Quotes',
      requiredIsAuthenticated: 'Valide sus credenciales de nuevo',
      resolution: 'Resolution|Resolutions',
      saleOrder: 'Sale order|Sale orders',
      sender: 'Remitente|Remitentes',
      settingDocument: 'Documents settings',
      settings: 'Setting|Settings',
      setup: 'Setup|Setups',
      setups: 'Setups',
      software: 'Software|Softwares',
      stock: 'Stock|Stocks',
      tax: 'Tax|Taxes',
      term: 'Terms|Terms',
      user: 'User|Users',
      vendor: 'Vendor|Vendors',
      view: 'View|Views',
      warehouse: 'Warehouse|Warehouses',
      waybillPurchase: 'Purchase waybill|Purchase waybills',
      waybillSale: 'Sales waybill|Sales waybills',
      withholding: 'Withholding|Withholdings'
    },
    equal: 'Equal',
    error: 'Error',
    event: 'Event|Events',
    exchangeRate: 'Exchange rate',
    expirationOfSignature: 'Expiration of signature',
    export: 'Export',
    false: 'No',
    favorite: 'Favorite',
    female: 'Female',
    field: 'Field|Fields',
    file: 'File|Files',
    filter: 'Filter|Filters',
    finalized: 'Finalized',
    firstName: 'First name',
    flat: 'Flat',
    footer: 'Footer',
    foreignCurrencyTransaction: 'Foreign currency transaction',
    forgotYourPassword: 'Did your forget your password',
    format: 'Format',
    from: 'From',
    gender: 'Gender',
    generate: 'Generate',
    generateVariants: 'Automatically generate variants',
    greaterThan: 'Greater than',
    greaterThanOrEqualTo: 'Greater than or equal to',
    habilitation: 'Habilitation',
    handleClose: 'Are you sure to close this dialog?',
    hasPos: 'POS',
    hasVariants: 'Tiene variantes',
    heigth: 'Heigth',
    help: 'Help',
    helpPortalPassword:
      'Leave the field blank to generate a password automatically',
    hi: 'Hi',
    identification: 'Identification',
    identificationNumber: 'ID Number',
    identificationType: 'ID Type',
    image: 'Image|Images',
    inactive: 'Inactive',
    initialStock: 'Initial stock',
    inProcess: 'In process',
    input: 'Input',
    inputRefund: 'Input by refund',
    insert: 'Create',
    interest: 'Interest',
    invalid: 'Invalid',
    inventory: 'Inventario',
    invoice: 'Invoice|Invoices',
    invoicePurchase: 'Purchase invoice|Purchase invoices',
    invoiceSaleComputer:
      'Invoice of sale traditional|Invoices of sale traditional',
    irreversibleProcess: 'This process can not be reversed!',
    isAvailable: 'Is available!',
    isCash: 'In cash',
    isCustomer: 'Is customer',
    isDefault: 'Is default',
    isEmpty: 'Is empty',
    isFor: 'Is for',
    isForBilling: 'Is for billing',
    isForPurchase: 'Is for purchase',
    isForSale: 'Is for sale',
    isForShipping: 'Is for shipping',
    isNotDefault: 'Is not default',
    isNotEmpty: 'Is not empty',
    isOnlyWarehouse: 'Is only warehouse',
    isProvider: 'Is provider',
    issuance: 'Issuance',
    item: 'Product|Products',
    itemHasInventoryControl: 'The item has inventory control?',
    itemHasPriceList: 'Price defined by list',
    itemInventoryControl: 'Inventory product',
    itemPriceIsByMargin: 'Price defined by margin on cost',
    itemWarranty: 'Warranty period (in days)',
    language: 'Lenguage',
    lastDevice: 'Last device',
    lastName: 'Last name',
    lastSession: 'Las session',
    length: 'Length',
    lessThan: 'Less than',
    lessThanOrEqualTo: 'Less than or equal to',
    line1: 'Line 1',
    line2: 'Line 2',
    linkDocument: 'Link of document',
    loading: 'Cargando..',
    loayltyProgram: 'Loyalty Program',
    login: 'Login',
    logistics: 'Logistics',
    loyalty: 'Loyalty',
    mainSupplier: 'Main supplier',
    male: 'Male',
    mandator: 'Client|Clients',
    manual: 'Manual',
    margin: 'Margin',
    markdown: 'Markdown',
    markup: 'Markup',
    maxDiscount: 'Maximum discount (%)',
    measuringUnit: 'Unit of measure| Units of measure',
    message: 'Message',
    messrs: 'Messrs.',
    miss: 'Miss.',
    mode: 'Mode',
    model: 'Model',
    monthly: 'Monthly',
    moreFields: 'More fields',
    mr: 'Mr.',
    mrs: 'Mrs.,',
    ms: 'Ms.',
    myAccount: 'My account',
    name: 'Name',
    nameOrCode: 'Name or code',
    neighborhood: 'Neighborhood',
    netToPay: 'Net to pay',
    new: 'New',
    newCategory: 'New category',
    newPassword: 'New password',
    newReply: 'Nuw reply',
    next: 'Next',
    no: 'No',
    notAvailable: 'Not available!',
    notContains: 'Not Contains',
    note: 'Notes',
    noteAdjustment: 'Note adjustment|Notes adjustments',
    notEqual: 'Not Equal',
    number: 'Number',
    of: 'Of',
    ok: 'Ok',
    operator: 'Operator|Operators',
    or: 'O',
    orderAutomatically: 'Order automatically',
    organization: 'Organization',
    other: 'Other',
    otherData: 'Other data',
    otherFields: 'Other fields',
    output: 'Output',
    outputRefund: 'Output by refund',
    outstandingInvoices: 'outstanding invoices',
    overdue: 'Overdue',
    paid: 'Paid',
    partial: 'Partial',
    partialPaid: 'Partial Paid',
    partialRefund: 'Partial refund',
    password: 'Password',
    passwordCertificate: 'Certificate password',
    payment: 'Payment|Payments',
    paymentAmount: 'Payment amount',
    paymentDate: 'Payment date',
    paymentFor: 'Payment for',
    paymentInvoice: 'Payment Invoice',
    paymentMean: 'Payment means',
    paymentReceipt: 'Payment receipt',
    paymentsMade: 'Payments made',
    percent: 'Percent',
    percentCost: 'Percent cost',
    percentCostManual: 'Percent cost individual',
    percentFromCost: '% from cost',
    percentPrice: 'Percent price',
    personType: 'Person type',
    phone: 'Phone',
    phoneCell: 'Phone cell',
    phoneHome: 'Phone home',
    phoneWork: 'Phone work',
    pickAMonth: 'Pick a month',
    portalPassword: 'Potal password',
    portalUser: 'Portal user',
    position: 'Position',
    postalCode: 'Código postal',
    prefix: 'Prefix',
    prepaymentsApplied: 'Prepayments applied',
    preview: 'Preview',
    previous: 'Previous',
    price: 'Price|Prices',
    priceList: 'Price list|Price lists',
    print: 'Print',
    process: 'Process',
    processing: 'Processing',
    processSuccess: 'The process was successfully executed!',
    product: 'Product|Products',
    production: 'Production',
    productWithVariants: 'Product with variants|Products with variants',
    profitAnalysis: 'Profit analysis',
    program: 'Program',
    purchase: 'Purchase|Purchases',
    purchaseOrder: 'Purchase order|Purchase orders',
    purchases: 'Purchases',
    purhases: 'Purhases',
    putThis: 'Put this',
    qualification: 'Qualification|Qualifications',
    qualify: 'Qualify',
    quantity: 'Quantity|Quantities',
    quote: 'Quote',
    rangeFinal: 'Range final',
    rangeInitial: 'Range initial',
    rate: 'Rate|Rates',
    reduction: 'Reduction',
    reference: 'Reference|References',
    refresh: 'Refresh',
    refund: 'Refund',
    regimeType: 'Regime type',
    region: 'Departament, State',
    registerForFree: 'Register for free',
    reject: 'Reject',
    rejected: 'Rejected',
    rejectReason: 'Reject reason',
    remark: 'Remark',
    reOrderPoint: 'Re-order point',
    reOrderQuantity: 'Re-order quantity',
    reply: 'Reply|Replies',
    report: 'Report',
    requested: 'Requested',
    required: 'Required',
    requiredNumber: 'Required number',
    requiresPermission: 'Requires permission',
    rescission: 'Rescission',
    resend: 'Resend',
    responsibleFor: 'Responsible for',
    result: 'Result',
    returned: 'Returned',
    role: 'Role|Roles',
    rounding: 'Rounding',
    row: 'Row|Rows',
    sale: 'Sale|Sales',
    saleOrder: 'Sale order|Sale orders',
    salePrice: 'Sale price',
    sales: 'Sales',
    salutation: 'Salutation',
    save: 'Save',
    saveAndSend: 'Save and send',
    saveOnly: 'Save only',
    search: 'Search',
    searchBy: 'Search by',
    searching: 'Searching...',
    searchItem: 'Search product',
    see: 'See',
    select: 'Select...',
    selectItem: 'Select product',
    sell: 'Sell',
    send: 'Send',
    sendEmail: 'Send email',
    sender: 'Sender|Senders',
    sendReply: 'Send reply',
    sendReview: 'Send review',
    sent: 'Sent',
    sentBy: 'Sent by',
    sequence: 'Secuence',
    setting: 'Setting',
    settingDocument: 'Documents settings',
    setups: 'Setups',
    shared: 'Shared',
    sharedBy: 'Compartido por',
    shipping: 'Shipping',
    shippingCost: 'Shipping cost',
    shippingFee: 'Shipping fee',
    shippingFeeHelp:
      'Shipping fees are excluded from discounts and taxes but they are included in your profit analysis.',
    showing: 'Showing',
    signup: 'Signup',
    size: 'Size|Sizes',
    softwareIdentification: 'Software identification',
    start: 'Start',
    startDate: 'Start date',
    startswith: 'Starts With',
    state: 'Sub status',
    status: 'Status',
    statusDian: 'DIAN status',
    stock: 'Stock|Stocks',
    stockAdjustment: 'Stock adjustment',
    stockInitial: 'Initial stock',
    subject: 'Subject',
    subStatus: 'Sub-status',
    supplier: 'Supplier',
    symbol: 'Symbol',
    tag: 'Tag|Tags',
    tax: 'Tax|Taxes',
    taxDeducted: 'Tax deducted',
    technicalKey: 'Technical key',
    template: 'Template|Templates',
    term: 'Terms',
    test: 'Test',
    third: 'Third',
    thisMonth: 'This month',
    timeFormat: 'Time format',
    timeZone: 'Time zone',
    title: 'Title',
    to: 'To',
    totalParcial: 'Total parcial',
    transfer: 'Transfer',
    transferBranch: 'Transfer to branch',
    transit: 'In transit',
    true: 'Yes',
    type: 'Type|Types',
    typeOfOperation: 'Type of operation',
    unitCost: 'Unit cost',
    unitDiscount: 'Unit discount',
    unitPrice: 'unit price',
    update: 'Update',
    updatedBy: 'Updated by',
    upload: 'Upload',
    used: 'Used',
    username: 'Username',
    valid: 'Valid',
    value: 'Value|Values',
    valueChange: 'Value change',
    variant: 'Variant|Variants',
    vendor: 'Vendor',
    verify: 'Verify',
    void: 'Void',
    warehouse: 'Warehouse|Warehouses',
    warning: 'Warning',
    waybillPurchase: 'Purchase waybill|Purchase waybills',
    waybillSale: 'Sales waybill|Sales waybills',
    website: 'Web site',
    weight: 'Weight',
    weightPerUnit: 'Weight per unit',
    width: 'Width',
    withholding: 'Withholding',
    year: 'Year|Years',
    yearly: 'Yearly',
    yes: 'Yes'
  },

  /*
      _______     _______..______      ___      .__   __.   ______    __
    |   ____|   /       ||   _  \    /   \     |  \ |  |  /  __  \  |  |
    |  |__     |   (----`|  |_)  |  /  ^  \    |   \|  | |  |  |  | |  |
    |   __|     \   \    |   ___/  /  /_\  \   |  . `  | |  |  |  | |  |
    |  |____.----)   |   |  |     /  _____  \  |  |\   | |  `--'  | |  `----.
    |_______|_______/    | _|    /__/     \__\ |__| \__|  \______/  |_______|
        */
  es: {
    helpResolution: 'Si la resolución solicitada en el portal Muisca no aparece en la parte inferior, debes realizar asociación de prefijos. Para poder asociar prefijos y te aparezca Aliaddo SAS en el portal de facturando electrónicamente (Producción), debes enviar los documentos necesarios para habilitarse con el testSetId, 8 facturas, 1 nota crédito y una 1 débito, válida que en el log de documentos tengas 8 facturas válidas en modo habilitación, 1 nota crédito y 1 nota débito.',
    abbreviation: 'Abreviatura',
    viewTutorial: 'Ver tutorial',
    accept: 'Aceptar',
    acceptance: 'Aceptación',
    acceptanceStatus: 'Estado aceptación cliente',
    accepted: 'Aceptada',
    acceptedByDefault: 'Aceptada por tiempo',
    acceptInvoice: 'Aceptar factura',
    acceptOrReject: 'Aceptar o rechazar factura',
    account: 'Mi cuenta',
    accruing: 'Devengados',
    action: 'Acción|Acciones',
    actionIrreversible: 'Acción irreversible',
    actions: 'Acciones',
    active: 'Activo',
    add: 'Agregar',
    addAttribute: 'Agregar otro atributo',
    addItem: 'Agregar ítem',
    addNew: 'Crear nuevo',
    addOn: 'Adicional|Adicionales',
    addonQuantity: 'Cantidad adicional',
    address: 'Dirección|Direcciones',
    addService: 'Adicionar Servicio',
    addServiceHelper:
      'Esta seguro de adicionar el servicio {service} a la empresa en modo {mode}',
    aditional: 'Adicional',
    adjust: 'Ajuste',
    adjustment: 'Ajuste',
    agree: 'Aceptar',
    all: 'Todos',
    amount: 'Valor',
    amountPayable: 'Total a pagar',
    amountToCredit: 'Valor a usar',
    amountWithheld: 'Valor retenido',
    and: 'Y',
    answer: 'Responder',
    approved: 'Aprobada',
    api: 'Configuración API',
    apiData: 'Datos de acceso a la API',
    apiDoc: 'Documentación Api',
    apiKey: 'Api Key',
    apiUrl: 'Dirección (url) api',
    appName: 'Aliaddo ISV',
    appNewRelease: 'Hemos actualizado la aplicación',
    appNewReleaseMessage:
      'Es necesario recargar la página para cargar los nuevos cambios, ten presente guardar antes deseas recargar ahora?',
    appReloading: 'Cargando de nuevo la aplicación',
    attachment: 'Adjunto|Adjuntos',
    attribute: 'Atributo|Atributos',
    attributeValue: 'Valor del atributo',
    available: 'Disponible!',
    balance: 'Saldo',
    bankCharge: 'Cargo bancario',
    bankCharges: 'Cargos bancarios (si los hay)',
    billData: 'Datos facturación',
    billing: 'Facturación',
    billingInformation: 'Datos de facturación',
    birthday: 'Fecha de nacimiento',
    blue: 'Blue',
    both: 'Ambos',
    branch: 'Sucursal|Sucursales',
    byDate: 'Por fecha',
    byStatus: 'Por estado',
    byYearByMonth: 'Por año y mes',
    call: 'Llamar',
    cancel: 'Cancelar',
    canceled: 'Anulada',
    canceledBy: 'Anulado por',
    cancellation: 'Anulación',
    card: 'Tarjeta',
    category: 'Categoría|Categorías',
    cellPhone: 'Móvil',
    change: 'Cambiar',
    changeAddress: 'Cambiar dirección',
    changePassword: 'Cambiar contraseña',
    changePlan: 'Cambiar plan',
    charge: 'Cargo|Cargos',
    chargeLevel: 'Cargos a nivel de',
    city: 'Ciudad|Ciudades',
    clicked: 'Click',
    clickViewMore: 'Click para ver más',
    close: 'Cerrar',
    closed: 'Cerrado',
    code: 'Código',
    codeOptionalAuto:
      'Código (Si no lo coloca se ingresa un consecutico automático)',
    codes: 'Códigos',
    codeSupplier: 'Código del producto según el proveedor',
    collectionExpense: 'Gastos por cobrar',
    column: 'Columna|Columnas',
    companies: 'Compañias',
    company: 'Empresa|Empresas',
    companyCreatedSuccess: 'Empresa creada con exito',
    companyName: 'Razón social o nombre de la empresa',
    complained: 'Queja',
    complete: 'Completa',
    completeDataForm: 'Debe completar los datos del formulario',
    component: 'Componente|Componentes',
    componentOrIngredient: 'Componentes o ingredientes',
    computerInvoice: 'Factura por computador',
    concept: 'Concepto',
    configDian: 'Configuración Dian',
    confirm: 'Esta seguro?',
    confirmationCode: 'Código de confirmación',
    confirmGenerateApiKey: 'Esta seguro de generar una nueva api key',
    confirmGenerateApiKeyMessage:
      'Esta seguro de generar un nuevo api key, recuerde que debe cambiar en todos los request el header <b>{header}</b> por el nuevo valor generado, a partir de ahora en adelante se va a validar todos los request con la key generada',
    consecutive: 'Consecutivo',
    consecutiveExternal: 'Consecutivo externo',
    consecutiveInitial: 'Consecutivo inicial',
    consignaturePassword: 'Confirmar contraseña',
    contact: 'Contacto|Contactos',
    contains: 'Contiene',
    continental: 'Continental',
    contingencyCheckbook: 'Talanario por contingencia',
    continue: 'Continuar',
    copy: 'Copiar',
    copyApiKey: 'Click para copiar llave api',
    copyApiUrl: 'Copiar url api',
    copyCudsDocument: 'Copiar CUDS del documento soporte, para enviar notas de ajustes',
    copyCufeDocument: 'Copiar CUFE de la factura, para enviar notas a esta factura',
    copyIdDocument: 'Copiar id (Llave) del documento dentro de Aliaddo',
    cost: 'Costo|Costos',
    costAdjustment: 'Ajuste de costos',
    costInitial: 'Costo inicial',
    country: 'País|Paises',
    create: 'Crear',
    createAccount: 'Crea tu cuenta de integrador',
    createCompany: 'Crear empresa',
    created: 'Creado',
    createdAt: 'Creado en',
    createdBy: 'Creado por',
    createFirstCompany: 'Debes crear primero una empresa',
    createFirstCompanyMessage:
      'Para iniciar debes crear una empresa para enviar documentos electrónicos a la DIAN',
    creditAvaible: 'Crédito disponible',
    creditBalance: 'Saldo a favor',
    creditNote: 'Nota crédito|Notas créditos',
    creditNoteBill: 'Nota crédito en compras|Notas crédito en compras',
    creditNoteInvoice: 'Nota crédito en ventas|Notas crédito en ventas',
    creditNoteOfInvoice: 'Nota crédito de factura',
    criterion: 'Criterio|Criterios',
    cuds: 'Cuds',
    cufe: 'Cufe',
    culture: 'Cultura',
    cune: 'Cune',
    currency: 'Moneda',
    currentCost: 'Costo actual',
    currentPassword: 'Contraseña actual',
    currentPlan: 'Plan actual',
    currentQuantity: 'Cantidad actual',
    customer: 'Cliente|Clientes',
    customerNote: 'Nota del cliente',
    cycle: 'Ciclo',
    dashboard: 'Dashboard',
    dashboardByCreated: 'Dashboard por fecha creación documento',
    dataBasic: 'Datos principales',
    dataSecondary: 'Datos secundarios',
    date: 'Fecha',
    dateDue: 'Fecha vencimiento',
    dateFormat: 'Formato de fecha',
    dateGen: 'Fecha Gen.',
    dateRange: 'Rango de fechas',
    day: 'Dia|Dias',
    debitNote: 'Nota débito|Notas débitos',
    debitNoteBill: 'Nota débito en compras|Notas débito en compras',
    debitNoteInvoice: 'Nota débito en ventas|Notas débito en ventas',
    debitNoteOfInvoice: 'Nota débito de factura',
    decimal: 'Decimal|Decimales',
    declined: 'Rechazada',
    deduction: 'Deducción',
    delete: 'Eliminar',
    deletedBy: 'Eliminado por',
    delivered: 'Entregado',
    demand: 'Demanda',
    demandaGlobal: '(Demanda Global) Sumarla al consumo de todas las empresas',
    demandaIndividual: '(Demanda Individual) Lo que consuma la empresa',
    demandGlobal: '(Demanda Global) Sumarla al consumo de todas las empresas',
    demandIndividual: '(Demanda Individual) Lo que consuma la empresa',
    department: 'Departamento',
    description: 'Descripción',
    detail: 'Detalle',
    detailFile: 'Detalle de correspondiente a un encabezado de un registro',
    dian: 'DIAN',
    dianReason: 'Inconsistencias al entrar la factura a la DIAN',
    dimension: 'Dimensión|Dimensiones',
    dimensionItem: 'Dimensiones (Largo x Ancho x Alto)',
    disabled: 'Inactivo',
    discount: 'Descuento',
    discountLevel: 'Descuentos a nivel de',
    document: 'Documento|Documentos',
    documentMode: 'Modo envio de documentos electrónicos',
    documentsInvalid: 'Documentos invalidos',
    documentsValid: 'Documentos validos',
    documentSupport: 'Documento soporte|Documentos soporte',
    documentType: 'Tipo de documento',
    done: 'Ejecutado',
    download: 'Descargar',
    downloadFileExample: 'Descargar plantilla con ejemplo',
    downloadPdf: 'Descargar archivo pdf',
    downloadZip: 'Descargar archivo comprimido',
    dr: 'Dr.',
    draft: 'Borrador',
    dropFileHere:
      'Arrastre y deje su archivo aquí o click para seleccionar uno',
    due: 'Vence',
    dueDate: 'Fecha de vencimiento',
    duplicate: 'Duplicar',
    edit: 'Editar',
    electronicInvoice: 'Factura de venta electrónica',
    email: 'E-mail',
    emailPersonal: 'E-mail personal',
    emailTemplate: 'Plantilla de e-mail',
    emailWork: 'E-mail trabajo',
    employee: 'Empleado',
    enabled: 'Activo',
    enabledLoyalty: 'Habilitar fidelización',
    enabledPortal: 'Permitir acceso al portal',
    enabledReview: 'Permitir calificar nuestro servicio o negocio',
    enablePos: 'Activar POS',
    end: 'Fin',
    endDate: 'Fecha final',
    endswith: 'Finaliza con',
    english: 'Ingles',
    entity: {
      address: 'Dirección|Direcciones',
      attribute: 'Atributo|Atributos',
      bill: 'Factura de compra|Facturas de compra',
      billing: 'Facturación',
      branch: 'Sucursal|Sucursales',
      branchUser: 'Usuario POS|Usuarios POS',
      branchDocument: 'Configuracion de documento|Configuracion de documentos',
      brand: 'Marca|Marcas',
      category: 'Categoria|Categorias',
      charge: 'Cargo|Cargos',
      companyPreference: 'Preferencias|Preferencias',
      contact: 'Contacto|Contactos',
      creditNoteBill: 'Nota crédito en compras|Notas crédito en compras',
      creditNoteInvoice: 'Nota crédito en venta|Notas crédito en ventas',
      currency: 'Moneda|Monedas',
      customer: 'Cliente',
      debitNoteBill: 'Nota débito en compras|Notas débito en compras',
      debitNoteInvoice: 'Nota débito en venta|Notas débito en ventas',
      discount: 'Descuento|Descuentos',
      document: 'Documento|Documentos',
      emailTemplate: 'Plantilla de e-mail|Plantillas de e-mail',
      home: 'MONOFACTURA|MONOFACTURA',
      inventory: 'Inventario',
      invoice: 'Factura de venta|Facturas de venta',
      invoicePurchase: 'Factura de compra|Facturas de compra',
      invoiceSale: 'Factura de venta|Facturas de venta',
      invoiceSaleComputer:
        'Factura de venta tradicional|Facturas de venta tradicional',
      item: 'Producto|Productos',
      itemPriceList: 'Precios por lista',
      login: 'Login|Login',
      Main: 'Principal|Principal',
      measuringUnit: 'Unidad de medida|Unidades de medida',
      myAccount: 'Mi cuenta|Mis cuentas',
      payment: 'Pago|Pagos',
      paymentMean: 'Medio de pago|Medios de pago',
      person: 'Tercero|Terceros',
      portal: 'portal',
      pos: 'Punto de venta|Puntos de venta',
      prepayment: 'Anticipo|Anticipos',
      price: 'Precio|Precios',
      priceList: 'Lista de precio|Listas de precios',
      product: 'Producto|Productos',
      purchaseOrder: 'Órden de compra|Órdenes de compra',
      quote: 'Cotización|Cotizaciones',
      register: 'Caja|Cajas',
      resolution: 'Resolución|Resoluciones',
      saleOrder: 'Órden de venta|Órdenes de venta',
      sender: 'Remitente|Remitentes',
      settingDocument: 'Configuración de documentos',
      settings: 'Configuración|Configuraciones',
      setup: 'Configuración|Configuraciones',
      setups: 'Configuraciones',
      software: 'Software|Softwares',
      stock: 'Inventario|Inventarios',
      tax: 'Impuesto|Impuestos',
      term: 'Plazo|Plazos',
      user: 'Usuario|Usuarios',
      vendor: 'Vendedor|Vendedores',
      view: 'Vista|Vistas',
      warehouse: 'Bodega|Bodegas',
      waybillPurchase: 'Remisión de compra|Remisiones de compra',
      waybillSale: 'Remisión de venta|Remisiones de venta',
      withholding: 'Retención|Retenciones'
    },
    environtment: 'Ambiente',
    equal: 'Igual',
    error: 'Error',
    errorImporting: 'Error importando',
    event: 'Evento|Eventos',
    exchangeRate: 'Tarifa de conversión',
    execute: 'Ejecutar',
    expirationOfSignature: 'Vencimiento de la firma',
    export: 'Exportar',
    failed: 'Fallido',
    false: 'No',
    favorite: 'Favorito',
    feature: 'Característica|Características',
    female: 'Mujer',
    field: 'Campo|Campos',
    fieldIsConfirmed: 'Este campo requiere que sea igual y confirmado',
    fieldIsEmail: 'Este campo requiere un dato tipo email',
    file: 'Archivo|Achivos',
    fileDimensionAllowed:
      'Tamaño de imagen preferido: 240px x 120px @ 72 DPI Tamaño máximo de 1MB y formatos permitidos png y jpg.',
    fileDocumcumentMesssage:
      'Tamaño del archivo máximo de 5M, archivo *.csv separado por ";", Tenga en cuenta que no debe llevar encabezado.',
    fileExample: 'Archivo ejemplo',
    fileFormatAllowed: 'El archivo no es del formato permitido',
    files: 'Archivos',
    filesImported: 'Archivos importados',
    fileSizeAllowed: 'La imagen excede los 5MB!',
    filter: 'Filtro|Filtros',
    finalized: 'Finalizado',
    firm: 'Firma',
    firstName: 'Nombres',
    flat: 'Plano',
    footer: 'Pie de pagina',
    foreignCurrencyTransaction: 'Transacción en moneda extranjera',
    forgotYourPassword: 'Olvidó su contraseña',
    format: 'Formato',
    frecuencyBilling: 'Ciclo facturación',
    from: 'Desde',
    gender: 'Género',
    generate: 'Generar',
    generateApiKey: 'Generar api key',
    generateToken: 'Generar token',
    generateVariants: 'Generar las variantes automáticamente',
    goToPayment: 'Ir a pagar',
    greaterThan: 'Mayor que',
    greaterThanOrEqualTo: 'Mayor o igual que',
    habilitation: 'Habilitación o ambiente de pruebas, (Envió documentos de habilitación a la DIAN)',
    halfLetter: 'Media carta',
    handleClose: 'Estas seguro de cerrar esta ventana?',
    hasAccount: '¿Ya tienes cuenta con nosotros? Ingresa aquí',
    hasNotesCr: 'Cr',
    hasNotesDb: 'Db',
    hasPos: 'POS',
    hasVariants: 'Tiene variantes',
    headerFile: 'Encabezado de cada registro del archivo',
    heigth: 'Alto',
    help: 'Ayuda',
    helpPortalPassword:
      'Deje el campo en blanco para generar una contraseña automaticamente.',
    hi: 'Hola',
    hideButtonRejectDocument: 'No mostrar botón de rechazar',
    historyChangePlan: 'Historial de cambio de plan',
    home: 'Inicio',
    howToFillTheFile: 'Como llenar el archivo ?',
    id: 'Id',
    idDocument: 'Id del documento',
    identification: 'Identificación',
    identificationIsNotNumber: 'El nit/cédula  {value} no es válido(a).',
    identificationNumber: 'No. de identificacion',
    identificationNumberCheck: 'Dígito de verificación',
    identificationType: 'Tipo de identificación',
    identifier: 'Número documento electrónico',
    image: 'Imagen|Imagenes',
    imported: 'Importados',
    importedCount: 'Cantidad importada',
    importFile: 'Importar archivo',
    importInvoice: 'Importar facturas',
    importInvoiceNoteCr: 'Importar notas crédito',
    importInvoiceNoteDb: 'Importar notas débito',
    importNotesCr: 'Importar notas crédito',
    importNotesDb: 'Importar notas débito',
    inactive: 'Inactivo',
    include: 'Incluido',
    indigo: 'Indigo',
    initialStock: 'Stock inicial',
    inProcess: 'En proceso',
    input: 'Entrada',
    inputHasPicture: 'Debe seleccionar por lo menos un archivo',
    inputNoHasFile: 'No se ha seleccionado archivos',
    inputNoHasFiles: 'No se han adjuntado archivos',
    inputRefund: 'Entrada por devolución',
    insert: 'Crear',
    integrator: 'Integrador',
    interest: 'Interés',
    internalServerError: 'Se ha producido un error realizando la petición',
    invalid: 'Inválido',
    invalidEmail: 'Email invalido',
    invalidPlan: 'Plan invalido',
    inventory: 'Inventario',
    invoice: 'Factura|Facturas',
    invoiceAndPlan: 'Plan y facturación',
    invoiceConsecutive: 'Consecutivo factura',
    invoiceCurrent: 'Factura vigente',
    invoiceDate: 'Fecha factura',
    invoiceDue: 'La factura esta vencida',
    invoiceNoteCr: 'Nota crédito|Notas crédito',
    invoiceNoteDb: 'Nota dédito|Notas dédito',
    invoiceOnlyValidDianSend:
      'Solo se pueden enviar facturas por email validadas por la DIAN',
    invoicePrefix: 'Prefijo factura',
    invoicePurchase: 'Factura de compra|Facturas de compra',
    invoiceSale: 'Factura de venta|Facturas de venta',
    invoiceSaleComputer:
      'Factura de venta tradicional|Facturas de venta tradicional',
    invoiceToPartner: 'Facturar al integrador',
    invoicing: 'Facturación',
    irreversibleProcess: 'Este proceso no se puede revertir!',
    isAvailable: 'Esta disponible!',
    isCash: 'En efectivo',
    isCustomer: 'Es cliente',
    isDefault: 'Predeterminado',
    isEmpty: 'Está vacio',
    isFor: 'Es para',
    isForBilling: 'Es para facturación',
    isForPurchase: 'Es para comprar',
    isForSale: 'Es para la venta',
    isForShipping: 'Es para entrega',
    isLoggedAs: 'Estas logueado como',
    isNotDefault: 'No está predeterminado',
    isNotEmpty: 'No está vacio',
    isOnlyWarehouse: 'Unicamente es una bodega',
    isPayVat: 'Es responsable de IVA',
    isProvider: 'Es proveedor',
    isSimpleRegime: 'Es régimen simple',
    issuance: 'Emisión',
    item: 'Producto|Productos',
    itemHasInventoryControl: 'Tiene control de inventario?',
    itemHasPriceList: 'Precio definido por lista',
    itemInventoryControl: 'Product inventariado',
    itemPriceIsByMargin: 'Precio definido por margen sobre el costo',
    itemsSelected: 'Items seleccionados',
    itemWarranty: 'Período de garantía (en días)',
    json: 'Json',
    kickOff: 'Inicializar configuración',
    kickOffAwaitMessage:
      'Estamos configurado tu cuenta, un poco de paciencia por favor (!)',
    kickOffError:
      'No se ha podido inicializar la configuración del servicio <b>{service}</b>',
    language: 'Lenguaje',
    lastDevice: 'Último dispositivo',
    lastLogin: 'Ultimo ingreso',
    lastName: 'Apellidos',
    lastSession: 'Última sesión',
    length: 'Largo',
    lessThan: 'Menor que',
    lessThanOrEqualTo: 'Menor o igual',
    line1: 'Linea 1',
    line2: 'Linea 2',
    linkDocument: 'Url del documento',
    loading: 'Cargando..',
    loayltyProgram: 'Pograma de fidelización',
    log: 'Log documentos',
    logEmail: 'Log de envio email',
    login: 'Iniciar sesión',
    logistics: 'Logistica',
    logo: 'Logo',
    logout: 'Cerrar sesión',
    loyalty: 'Fidelización',
    mainSupplier: 'Principal proveedor',
    male: 'Hombre',
    mandator: 'Mandante|mandantes',
    manual: 'Manual',
    margin: 'Margen',
    markdown: 'Disminuir',
    markup: 'Aumentar',
    maxDiscount: 'Descuento máximo (%)',
    maxlength: 'Ancho del campo',
    measurementUnitsFromDian: 'Unidades de medida desde la DIAN',
    measuringUnit: 'Unidad de medida| Unidades de medida',
    menu: 'Menu',
    message: 'Mensaje',
    messageChangeProduction:
      'Después de entrar a producción no se puede retroceder este cambio, este método es necesario para poder enviar facturas a la DIAN validas, realice este cambio cuando ya este habilitado en la DIAN y hubiera asociado prefijos pasando el test de pruebas o documentos necesarios para habilitarse como facturador electrónico',
    messrs: 'Sres.',
    minimunInputLength: 'Debes ingresar al menos {0} caracteres',
    miss: 'Srta.',
    mode: 'Modalidad',
    modeBilling: 'Modo facturación',
    model: 'Modelo',
    production: 'Producción',
    modeOperation: 'Modo operación',
    actionConfirm: 'Estas seguro?',
    actionConfirmHelper:
      'Estas a punto de completar la acción, da click en confirmar para continuar?',
    month: 'Mes|mes',
    monthly: 'Mensual',
    moreFields: 'Mas campos',
    mr: 'Sr.',
    mrs: 'Sra.',
    ms: 'Dña.',
    myAccount: 'Mi cuenta',
    name: 'Nombre',
    nameOrCode: 'Nombre o código',
    neighborhood: 'Barrio',
    netToPay: 'Neto a pagar',
    new: 'Nueva',
    newCategory: 'Nueva categoría',
    newPassword: 'Nueva Contraseña',
    newReply: 'Nueva respuesta',
    next: 'Siguiente',
    no: 'No',
    noMatchFound: 'No se encontraron coincidencias',
    noResult: 'No se encontraron resultados',
    noService: '¿Aún no tienes contratado nuestro servicio?',
    noServiceHelper:
      'No tienes este servicio, quieres comenzar a disfrutar, comunicate pronto con tu asesor comercial',
    notAvailable: 'No disponible!',
    notContains: 'No contiene',
    note: 'Notas',
    noteAdjustment: 'Nota de ajuste|Notas de ajustes',
    notEqual: 'No es igual',
    number: 'Número',
    numberDocument: 'Número documento',
    observation: 'Observación',
    of: 'De',
    ok: 'Bien',
    opened: 'Abierto',
    operationMode: 'Modo operación',
    operator: 'Operador|Operadores',
    or: 'O',
    orderAutomatically: 'Ordenar automaticamente',
    organization: 'Organización',
    other: 'Otro',
    otherData: 'Otros datos',
    otherFields: 'Otros campos',
    output: 'Salida',
    outputRefund: 'Salida por devolución',
    outstandingInvoices: 'Facturas pendientes',
    overdue: 'Vencimiento',
    package: 'Paquete',
    packageGlobal: 'Consumir las facturas de mi paquete',
    packageIndividual: 'Paquete (Solo para esta empresa)',
    paid: 'Pagado',
    paqueteGlobal: '(Paquete Global) Consumir de mi paquete como integrador',
    partial: 'Parcial',
    partialPaid: 'Pago parcial',
    partialRefund: 'Devolución parcial',
    password: 'Contraseña',
    passwordCertificate: 'Contrase del certificado',
    passwordNew: 'Nueva contraseña',
    passwordOld: 'Contraseña antigua',
    passwordRepeat: 'Repita contraseña',
    passwordsNoMatch: 'Las contraseñas no coinciden',
    pay: 'Realizar pago',
    payment: 'Pago|Pagos',
    paymentAmount: 'Valor del pago',
    paymentDate: 'Fecha de pago',
    paymentFor: 'Pago para',
    paymentInvoice: 'Valor de la factura',
    paymentMean: 'Medio de pago|Medios de pago',
    paymentMeansFromDian: 'Medios de pago desde la DIAN',
    paymentReceipt: 'Recibo de pago',
    paymentsMade: 'Pagos realizados',
    payroll: 'Nómina|nómina|Nóminas',
    payrollAdjustment: 'Ajuste Nóminas',
    payrollDelete: 'Notas de Eliminación Nómina',
    payrollReplace: 'Notas de Reemplazo Nómina',
    pdf: 'Pdf',
    pending: 'Pendiente',
    percent: 'Porcentaje',
    percentCost: 'Porcentaje sobre el costo',
    percentCostManual: 'Porcentaje sobre el costo individual',
    percentFromCost: '% del costo',
    percentPrice: 'Porcentaje sobre el precio',
    period: 'Periodo',
    person: 'Tercero|Terceros',
    personType: 'Tipo de persona',
    phone: 'Teléfono',
    phoneCell: 'Teléfono móvil',
    phoneHome: 'Teléfono hogar',
    phoneWork: 'Teléfono trabajo',
    pickAMonth: 'Elige un mes',
    plan: 'Plan',
    portalPassword: 'Contraseña del potal',
    portalUser: 'Usuario del portal',
    position: 'Cargo',
    postalCode: 'Código postal',
    prefix: 'Prefijo',
    prepaymentsApplied: 'Anticipos aplicados',
    preview: 'Vista previa',
    previous: 'Anterior',
    price: 'Precio|Precios',
    priceList: 'Lista de precio|Listas de precios',
    print: 'Imprimir',
    process: 'Procesar',
    processing: 'Procesando',
    processSuccess: 'El proceso se ejecutado con éxito!',
    product: 'Producto|Productos',
    productWithVariants: 'Producto con variantes|Productos con variantes',
    profile: 'Perfil',
    profitAnalysis: 'Análisis de resultado',
    program: 'Programar',
    purchase: 'Compra|Compras',
    purchaseOrder: 'Órden de compra|Órdenes de compra',
    purchases: 'Compras',
    purhases: 'Compras',
    putThis: 'Colocar este',
    qualification: 'Calificación|Calificaciónes',
    qualify: 'Calificar',
    quantity: 'Cantidad|Cantidades',
    quote: 'Cotización|Cotizaciones',
    rangeFinal: 'Rango final',
    rangeInitial: 'Rango inicial',
    rate: 'Tarifa|Tarifas',
    reason: 'Razones',
    recipient: 'Destinatario',
    reduction: 'Reducción',
    reference: 'Referencia|Referencias',
    refresh: 'Refrescar',
    refund: 'Devolución',
    regenerate: 'Generar de nuevo',
    regimeType: 'Tipo de regimen',
    region: 'Departamento, Estado',
    registerForFree: 'Regístrate gratis',
    reject: 'Rechazar',
    rejected: 'Rechazada',
    rejectReason: 'Motivo de rechazo',
    remark: 'Comentario',
    reOrderPoint: 'Punto de re-orden',
    reOrderQuantity: 'Cantidad para re-orden',
    reply: 'Respuesta|Respuestas',
    report: 'Reporte',
    contributorKind: 'Tipo contribuyente',
    request: 'Request',
    regimeSimple: 'Regimen Simple',
    responsibleForVat: 'Responsable de IVA',
    none: 'Ninguno',
    requested: 'Solicitado',
    requestHeaderApiKey:
      'Para poder autenticarse en todos los métodos se debe enviar un encabezado de la siguiente forma ( x-api-key : {apiKey} )',
    required: 'Este campo es requerido',
    requiredIsAuthenticated: 'Valide sus credenciales de nuevo',
    requiredNumber: 'El valor debe ser un número',
    requiresPermission: 'Requiere permiso',
    rescission: 'Rescisión',
    resend: 'Reenviar',
    resolution: 'Resolución|Resoluciones',
    resolutionKey: 'Clave técnica',
    resolutionNumber: 'Número',
    resolutionPrefix: 'Prefijo',
    resolutionRangeFinal: 'Rango final',
    resolutionRangeInitial: 'Rango inicial',
    resolutionValidFrom: 'Desde',
    resolutionValidUntil: 'Hasta',
    responsabilites: 'Responsabilidades fiscales',
    responseDian: 'Respuesta DIAN',
    responsibilitiesFiscal:
      'Responsabilidades fiscales|responsabilidades fiscales',
    responsibleFor: 'Responsable de',
    result: 'Resultado',
    returned: 'Devuelta',
    rol: 'Rol',
    role: 'Rol|Roles',
    rounding: 'Redondeo',
    row: 'Fila|Filas',
    rut: 'RUT',
    rutFileMessage: 'Arrastra o selecciona el RUT de la empresa a crear',
    sale: 'Venta|Ventas',
    saleOrder: 'Órden de venta|Órdenes de venta',
    salePrice: 'Precio de venta',
    sales: 'Ventas',
    salutation: 'Saludo',
    save: 'Guardar',
    saveAndSend: 'Guardar y enviar',
    saveOnly: 'Guardar unicamente',
    search: 'Buscar',
    searchBy: 'Buscar por',
    searching: 'Buscando...',
    searchItem: 'Buscar item',
    security: 'Seguridad api',
    see: 'Ver',
    select: 'Seleccionar',
    selectCompany: 'Seleccione empresa, dígite para buscar',
    selectCompanyFirst: 'Seleccione empresa primero',
    selectCompanyFirstHelper:
      'Debes seleccionar primero en la parte superior la empresa con la que vas a trabajar',
    selectCompanyHeader: 'Seleccione empresa',
    selectDate: 'Seleccione fecha',
    selectFile: 'Selecciona un archivo',
    selectFirstCompany:
      'Debe seleccionar primero la empresa con la cual va a trabajar en el módulo en la parte superior izquierda',
    selectItem: 'Seleccionar articulos',
    selectModeBill:
      'Debes seleccionar por lo menos en un servicio un modo de facturación',
    selectPlan: 'Debes seleccionar un plan',
    sell: 'Vender',
    send: 'Enviar',
    sendEmail: 'Enviar correo',
    sender: 'Remitente|Remitentes',
    sendInvoiceAndBuildReport: 'Enviando facturas y construyendo reporte',
    sendNotification: 'Enviar notificación',
    sendReply: 'Enviar respuesta',
    sendReview: 'Enviar evaluación',
    sent: 'Enviada',
    sentAt: 'Enviado el',
    sentBy: 'Enviado por',
    sequence: 'Consecutivo',
    service: 'Servicio',
    serviceMode: 'Modo operación',
    setting: 'Configuración',
    settingDocument: 'Configuración de documentos',
    settingsCreating: 'Se esta creando la configuración',
    setups: 'Configuraciones',
    shared: 'Compartido',
    sharedBy: 'Compartido por',
    shipping: 'Envío',
    shippingCost: 'Costo de envío',
    shippingFee: 'Cargos de envío',
    shippingFeeHelp:
      'Los gastos de envío están excluidos de los descuentos e impuestos, pero están incluidos en su análisis de beneficios.',
    showing: 'Mostrando',
    signup: 'Suscripción',
    signWithApp: 'Estas firmando con Aliaddo',
    signWithAppHelper:
      'Recuerda que si tu plan incluye la firma debes enviar RUT y contrato mandato de {company}, de lo contrario debes cargar tu propia firma.',
    signWithNubixar: 'Firmar con Aliaddo',
    size: 'Tamaño|Tamaños',
    softwareIdentification: 'Identificación del software',
    standard: 'Standard',
    start: 'Inicio',
    startDate: 'Fecha inicial',
    startswith: 'Empieza por',
    state: 'Sub estado',
    status: 'Estado',
    statusClient: 'Estado aceptación cliente',
    statusCode204: 'No se ha encontrado registros',
    statusCode404: 'No se ha encontrado la ruta',
    statusCode406:
      'No pudo producir una respuesta de acuerdo a la lista de valores',
    statusDian: 'Estado DIAN',
    stock: 'Existencia|Existencias',
    stockAdjustment: 'Ajuste de existencias',
    stockInitial: 'Inventario inicial',
    subject: 'Asunto',
    subStatus: 'Sub-estado',
    supplier: 'Proveedor',
    swagger: 'Documentación Swagger',
    symbol: 'Símbolo',
    tacit: 'Tacitamente',
    tag: 'Etiqueta|Etiquetas',
    tax: 'Impuesto|Impuestos',
    taxDeducted: 'Impuesto retenido',
    taxes: 'Impuestos',
    taxesFromDian: 'Impuestos desde la DIAN',
    technicalKey: 'Clave técnica',
    template: 'Plantilla|Plantillas',
    term: 'Plazo',
    test: 'Pruebas',
    testId:
      'Test Id proporcionado por la DIAN para enviar los documentos de habilitación',
    testSetId: 'Test set id habilitación',
    textCopied: 'Texto copiado en el portapapeles',
    textNotCopied: 'No se ha podido copiar el texto en el portapapeles',
    third: 'Tercero',
    thisMonth: 'Este mes',
    timeFormat: 'Formato de hora',
    timeZone: 'Zona horaria',
    title: 'Titulo',
    to: 'A|a',
    token: 'Token',
    total: 'Total',
    totalParcial: 'Subtotal',
    transfer: 'Traslado',
    transferBranch: 'Traslado a sucursal',
    transit: 'En tránsito',
    true: 'Si',
    type: 'Tipo|Tipos',
    typeForSearch: 'Dígita para buscar',
    typeOfOperation: 'Tipo de operación',
    unitCost: 'Costo unitario',
    unitDiscount: 'Descuento unitario',
    unitMeasure: 'Unidad de medida|Unidades de medida',
    unitPrice: 'Precio unitario',
    update: 'Actualizar',
    updatedBy: 'Actualizado por',
    upload: 'Importar',
    uploadSignature: 'Subir firma',
    uploadSinger: 'Debes volver a cargar la firma',
    url: 'Url',
    use: 'Usar',
    used: 'Usado',
    user: 'Usuario|usuario|Usuarios|usuarios',
    userName: 'Usuario',
    valid: 'Válido',
    validateForm:
      'Verifique que hubiera diligenciado correctamente el formulario (!)',
    value: 'Valor|Valores',
    valueChange: 'Cambio por valor',
    variant: 'Variante|Variantes',
    vendor: 'Vendedor',
    verify: 'Verificar',
    viewCode: 'Ver códigos',
    viewDoc: 'Ver como llenar el archivo',
    viewJson: 'Ver objeto json',
    viewLog: 'Ver log',
    void: 'Anular',
    warehouse: 'Bodega|Bodegas',
    warning: 'Advertencia',
    warningFirstApiKey:
      'Debe seleccionar primero una empresa y generar un api key para realizar la consulta',
    waybillPurchase: 'Remisión de compra|Remisiones de compra',
    waybillSale: 'Remisión de venta|Remisiones de venta',
    webhook: 'Url notificación|url notificación',
    webhookConfirmMessage:
      'A la url de notificación que colocaste te vamos a enviar los datos de estado cuando el documento electrónico sea enviado a la DIAN',
    website: 'Sitio Web',
    webSite: 'Sitio web',
    weight: 'Peso',
    weightPerUnit: 'Peso por unidad',
    width: 'Ancho',
    withholding: 'Retención',
    closeDialog: 'Cerrar dialogo',
    signDocumentsRejected: 'Documentación rechazada',
    withholdings: 'Retenciones',
    xml: 'XML',
    xmlApplicationResponse: 'Xml Respuesta',
    xmlDian: 'XML DIAN',
    year: 'Año|Años',
    yearly: 'Anual',
    processApprove: 'Pendiente por aprobación',
    documentsApproved: 'Documentos aprobados',
    documentsApprovedHelper:
      'El proceso de validación de documentos ha sido realizado con exito, ya puedes emitir y transmitir documentos electrónicos',
    processApproveHelper:
      'Nuestro equipo esta revisando la documentación adjunta, a vuelta de correo del usuario que subio los archivos estaremos enviando el resultado, intenta consultar mas tarde',
    generated: 'Generado',
    dateGenerated: 'Fecha Generado',
    uploadDocument: 'Subir documento|Subir documentos',
    validUntil: 'Valido hasta',
    uploadDocumentsIsRequired:
      'Debes subir los documentos requeridos para poder firmar con Aliaddo',
    uploadSignRequired:
      'Debes subir la firma electronica en formato .pfx y adicionar la clave de la misma',
    copyId: 'Copiar Id',
    sign: 'Firma',
    chamberCommerce: 'Cámara y comercio',
    legalRepresentative: 'Cédula representante legal',
    contractMandate: 'Contrato mandato',
    signHelper:
      'La firma debe estar en formato .pfx, debes ingresar la contraseña, recuerda realizar una prueba de envió para validar la correcta instalación',
    uploadSign: 'Subir firma',
    appNewContent:
      'Vamos a descargar el contenido necesario para que puedas trabajar, ya puedes comenzar a utilizar la aplicación',
    singWithApp: 'Firmar con Aliaddo SAS',
    copyCune: 'Copiar CUNE',
    uploadSignerDocuments:
      'Debes cargar los documentos para poder utilizar la firma electronica de Aliaddo',
    uploadSignerDocumentsHelper:
      'Hemos ampliado el plazo para que subas los documentos necesarios y puedas utilizar la firma de Aliaddo, tienes hasta el día 9 de junio para realizar este cargue de documentos, de lo contrario el sistema asumirá que la empresa que emite el documento va a utilizar su propia firma, al no encontrar la firma los documentos no se podrán transmitir.',
    uploadDocuments: 'Subir documentos',
    completeYourRegister: 'Completa los datos de tu registro',
    recordNotFound: 'No se encontraron coincidencias',
    searchPlaceholder: 'Dígita para buscar',
    uploadDocumentsHelper:
      'Debes subir todos los documentos solicitados en el siguiente  formulario, recuerda que la camara y comercio no debe superar los 30 días, un agente de nuestro equipo verificará los documentos y los aceptara',
    yes: 'Si',
    max: 'Ha superado el máximo de caracteres permitidos',
    zip: 'Zip'
  }
}
export const i18n = new VueI18n({
  locale: 'es',
  messages
})
