import * as buttonsForm from './buttons-form'
import * as buttonsGrid from './buttons-grid'
import * as inputs from './inputs'
import * as selects from './selects'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import Vue from 'vue'
import dialogLog from './dialogs/dialog-logs.vue'
import pdfViewer from './pdf-viewer'
import { toKebabCase } from '@/filters'

/* ----------  inputs  ----------*/
Object.keys(inputs).forEach(key => Vue.component(toKebabCase(key), inputs[key]))

/* ----------  buttons  ----------*/
Object.keys(buttonsForm).forEach(key => Vue.component(toKebabCase(key), buttonsForm[key]))

/* ----------  buttons grid  ----------*/
Object.keys(buttonsGrid).forEach(key => Vue.component(toKebabCase(key), buttonsGrid[key]))

/* ----------  selects  ----------*/
Object.keys(selects).forEach(key => Vue.component(toKebabCase(key), selects[key]))

/* ----------  vee validation  ----------*/
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

/* ----------  others  ----------*/
Vue.component('DialogLog', dialogLog)
Vue.component('PdfViewer', pdfViewer)
