<template>
  <el-button type="text" :to-name="toName" :to-params="toParams" @click="onClick">
{{ $t('cancel') }}
</el-button>
</template>
<script>
export default {
  props: {
    toName: String,
    toParams: Object,
  },
  methods: {
    onClick(event) {
      if (this.toName) {
        this.$router.push({ name: this.toName, params: this.toParams })
      } else {
        this.$emit('set-click', event)
      }
    },
  },
}
</script>
