<template>
    <el-date-picker
        v-model="vModel"
        :value="value"
        :readonly="readonly"
        :placeholder="placeholder"
        type="date"
        value-format="yyyy/MM/dd"
        :format="format"
        :disabled="disabled"
        :clearable="clearable"
        @change="onChange"
    ></el-date-picker>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    clearable: Boolean,
    readonly: Boolean,
    placeholder: String,
    value: String,
  },
  data() {
    return {
      vModel: new Date(),
      format: this.$store.getters.tokenApp.tokenData.companyDateFormat
        .replace('YYYY', 'yyyy')
        .replace('DD', 'dd'),
    }
  },
  watch: {
    vModel(val) {
      if (val) {
        this.$emit('input', val)
        this.$emit('set-date', val)
      }
    },
    value(val) {
      console.log('Value -------- Date', val)
      if (val) {
        this.vModel = val
        this.$emit('input', val)
        this.$emit('set-date', val)
      } else {
        this.vModel = val
        this.$emit('input', val)
        this.$emit('set-date', val)
      }
    },
  },
  created() {
    this.onChange(
      `${this.vModel.getFullYear()}/${this.vModel.getMonth()}/${this.vModel.getDay()}`,
    )
  },
  methods: {
    onChange(value) {
      console.log('OnChangeValue-----', value)
      this.$emit('input', value)
      this.$emit('set-date', value)
    },
  },
}
</script>
