var render = function render(){var _vm=this,_c=_vm._self._c;return _c('multiselect',{attrs:{"value":_vm.value,"options":_vm.options.map(item => item.code),"multiple":true,"placeholder":`${_vm.$tc('select')} ${_vm.$tc(
    'responsibilitiesFiscal',
    2
  )} ${'...'}`,"custom-label":option =>
      _vm.options.find(item => item.code == option)
        ? _vm.options.find(item => item.code == option).name
        : ''},on:{"select":_vm.onSelect,"remove":_vm.onRemove},scopedSlots:_vm._u([{key:"selection",fn:function({ values, search, isOpen }){return [(values.length && isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(values.length)+" "+_vm._s(_vm.$tc('itemsSelected'))+" ")]):_vm._e()]}},{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v("("+_vm._s(_vm.options.find(item => item.code == props.option) ? _vm.options.find(item => item.code == props.option).code : '')+")")]),_c('span',{staticClass:"option__small"},[_vm._v("  "+_vm._s(_vm.options.find(item => item.code == props.option) ? _vm.options.find(item => item.code == props.option).name : ''))])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }