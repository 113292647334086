<template>
    <b-select
        :value="value"
        :placeholder="$tc('selectCompany')"
        @input="onChange"
    >
        <option
            v-for="option in options"
            :key="option.id"
            :value="option.id"
        >
            {{ option.identificationTypeCode }}
        </option>
    </b-select>
</template>
<script>
export default {
  props: ['value'],
  data() {
    return {
      options: []
    }
  },
  mounted() {
    const _this = this
    _this.$http
      .get(`${process.env.VUE_APP_API_HUB}identificationTypes`)
      .then(response => {
        _this.options = response.body
      })
  },
  methods: {
    onChange(event) {
      const _this = this
      _this.$emit('input', event)
      _this.$emit(
        'set-identificationTypeName',
        this.options.find(k => k.id === event).identificationTypeName
      )
      _this.$emit(
        'set-identificationTypeCode',
        this.options.find(k => k.id === event).identificationTypeCode
      )
    }
  }
}
</script>
