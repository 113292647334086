import { SnackbarProgrammatic as Snackbar } from 'buefy'
import { i18n } from '@/i18n'

export const onCopyStatusSuccess = copied => {
  if (copied.action === 'copy' && copied.text) {
    Snackbar.open(i18n.tc('textCopied'))
  }
}
export const onCopyStatusError = copied => {
  if (copied.action === 'copy' && copied.text) {
    Snackbar.open({
      message: i18n.tc('textNotCopied'),
      type: 'is-danger',
    })
  }
}
