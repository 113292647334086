<template>
  <div>
    <b-field :label="$tc('company')" horizontal grouped>
      <b-autocomplete
        v-model="models.companyName"
        name="companyName"
        :data="filteredCompanies"
        :keep-first="true"
        :loading="loading.main"
        :open-on-focus="true"
        :placeholder="$tc('selectCompany')"
        field="companyName"
        expanded
        icon-right="close-circle"
        icon-right-clickable
        @icon-right-click="onSelectCompany('')"
        @select="onSelectCompany"
      >
      </b-autocomplete>
      <p class="control">
        <b-dropdown aria-role="list">
          <template #trigger="">
            <b-button
              type="is-primary"
              icon-right="caret-down"
              icon-left="key-skeleton"
            >
              <span> &nbsp;&nbsp;x-api-key</span>
            </b-button>
          </template>
          <b-tooltip :label="$tc('copyApiKey')" type="is-dark">
            <b-dropdown-item
              v-for="item in companySelected.services"
              :key="item.serviceId"
              v-clipboard:copy="item.companyServiceApiKey"
              v-clipboard:error="onCopyStatusError"
              v-clipboard:success="onCopyStatusSuccess"
              aria-role="listitem"
            >
              <div class="media">
                <div class="media-content">
                  <h3>{{ item.serviceName }}</h3>
                  <small>{{ $tc(item.companyServiceModeBill) }}</small>
                  <div>
                    <b>{{ item.companyServiceApiKey }}</b>
                  </div>
                </div>
              </div>
            </b-dropdown-item>
          </b-tooltip>
        </b-dropdown>
      </p>
      <p class="control">
        <b-button type="is-primary" @click="onRoute('company-add', { id: '' })">
          {{ $tc('createCompany') }}
        </b-button>
      </p>
    </b-field>
    <b-loading
      :active.sync="loading.main"
      :can-cancel="true"
      :is-full-page="true"
    >
    </b-loading>
  </div>
</template>
<script>
import { onCopyStatusError, onCopyStatusSuccess } from '@/filters/helpers'
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import { mapActions, mapGetters } from 'vuex'

/* #region  use
------------------------------------------------- */
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

/* #endregion
-------------------------------------------------- */
export default {
  name: 'SelectCompany',
  data() {
    return {
      loading: {
        main: false
      },
      models: {
        companyName: ''
      },
      value: {},
      data: []
    }
  },
  methods: {
    onCopyStatusSuccess,
    onCopyStatusError,
    ...mapActions(['setCompanySelected', 'setCompanies']),
    onRoute(routeName, params) {
      if (routeName !== this.$route.name) {
        this.$router.push({
          name: routeName,
          params
        })
      }
    },
    onSelectCompany(selectedValue) {
      const _this = this
      if (selectedValue) {
        _this.loading.main = true
        _this.$http
          .get(
            `${process.env.VUE_APP_API_HUB}apps/companies/${selectedValue.id}/services/${process.env.VUE_APP_SERVICE_API_KEY}/findByApi`
          )
          .then(result => {
            _this.setCompanySelected({})
            _this.setCompanySelected({
              ...selectedValue,
              services: result.body
            })
            if (_this.companySelected) {
              _this.models.companyName = _this.companySelected.companyName
            }
          })
          .finally(() => (_this.loading.main = false))
      } else {
        _this.setCompanySelected({
          services: []
        })
        _this.models.companyName = ''
      }
      _this.$emit('input', selectedValue)
      _this.$emit('update:value', selectedValue)
    },
    getCompanies() {
      const _this = this
      _this.loading.main = true
      _this.$http
        .get(
          `${process.env.VUE_APP_API_HUB}apps/services/${process.env.VUE_APP_SERVICE_API_KEY}/companiesByPartner`
        )
        .then(result => {
          _this.data = result.body
          if (_this.companySelected) {
            _this.models.companyName = _this.companySelected.companyName
          }
        })
        .finally(() => (_this.loading.main = false))
    }
  },
  computed: {
    ...mapGetters(['companies', 'companySelected', 'tokenApp']),
    filteredCompanies() {
      const _this = this

      return _this.data.filter(
        item =>
          !_this.models.companyName ||
          item.companyName
            .toString()
            .toLowerCase()
            .indexOf(_this.models.companyName.toLowerCase()) >= 0
      )
    }
  },
  watch: {
    companySelected(newValue, oldValue) {
      const _this = this
      if (newValue && newValue.company) {
        _this.models.companyName = newValue.company.companyName
      }
    },
    companies(newValue, oldValue) {
      const _this = this
      _this.data = [..._this.data, ...newValue]
    },
    $route(to, from) {
      if (to.name === 'change-password') {
        this.onSelectCompany('')
        this.value = ''
      }
    }
  },
  created() {
    this.getCompanies()
  }
}
</script>
