const regions = [
  { name: 'Amazonas', code: '91', iso: 'AMA' },
  { name: 'Antioquia', code: '05', iso: 'ANT' },
  { name: 'Arauca', code: '81', iso: 'ARA' },
  { name: 'Atlántico', code: '08', iso: 'ATL' },
  { name: 'Bogotá', code: '11', iso: 'DC' },
  { name: 'Bolívar', code: '13', iso: 'BOL' },
  { name: 'Boyacá', code: '15', iso: 'BOY' },
  { name: 'Caldas', code: '17', iso: 'CAL' },
  { name: 'Caquetá', code: '18', iso: 'CAQ' },
  { name: 'Casanare', code: '85', iso: 'CAS' },
  { name: 'Cauca', code: '19', iso: 'CAU' },
  { name: 'Cesar', code: '20', iso: 'CES' },
  { name: 'Chocó', code: '27', iso: 'CHO' },
  { name: 'Córdoba', code: '23', iso: 'COR' },
  { name: 'Cundinamarca', code: '25', iso: 'CUN' },
  { name: 'Guainía', code: '94', iso: 'GUA' },
  { name: 'Guaviare', code: '95', iso: 'GUV' },
  { name: 'Huila', code: '41', iso: 'HUI' },
  { name: 'La Guajira', code: '44', iso: 'LAG' },
  { name: 'Magdalena', code: '47', iso: 'MAG' },
  { name: 'Meta', code: '50', iso: 'MET' },
  { name: 'Nariño', code: '52', iso: 'NAR' },
  { name: 'Norte de Santander', code: '54', iso: 'NSA' },
  { name: 'Putumayo', code: '86', iso: 'PUT' },
  { name: 'Quindío', code: '63', iso: 'QUI' },
  { name: 'Risaralda', code: '66', iso: 'RIS' },
  { name: 'San Andrés y Providencia', code: '88', iso: 'SAP' },
  { name: 'Santander', code: '68', iso: 'SAN' },
  { name: 'Sucre', code: '70', iso: 'SUC' },
  { name: 'Tolima', code: '73', iso: 'TOL' },
  { name: 'Valle del Cauca', code: '76', iso: 'VAC' },
  { name: 'Vaupés', code: '97', iso: 'VAU' },
  { name: 'SuVichadacre', code: '99', iso: 'VID' },
]

export default regions
