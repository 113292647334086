<template>
    <b-select
        :expanded="true"
        :value="value"
        :placeholder="`${$tc('select')} ${$tc('operationMode')}...`"
        @input="onChange"
    >
        <option value="Habilitation">
{{ $tc('habilitation') }}
</option>
        <option value="Production">
{{ $tc('production') }}
</option>
    </b-select>
</template>
<script>
export default {
  name: 'SelectServiceMode',
  props: {
    value: {
      type: String,
      default: ''
    },
    expanded: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    }
  }
}
</script>
