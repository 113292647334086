import { i18n } from '../i18n'

const culture = 'es-CO'

export function status(value) {
  if (value) {
    switch (value) {
      case 'active':
        return `<el-tag type="success">${i18n.t(value)}</el-tag>`
      case 'inactive':
        return `<el-tag type="danger">${i18n.t(value)}</el-tag>`
      default:
        return ''
    }
  }
}
export function capital(value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}
export function state(value) {
  if (value) {
    return i18n.t(value)
  }

  return '--'
}
export function formatToLocale(value) {
  if (value) {
    return i18n.t(value)
  }

  return '--'
}
