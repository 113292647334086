<template>
  <b-select :value="value" :placeholder="`${$tc('status')} DIAN`" @input="onSelect">
    <!-- <option key="All" value="all">{{ $tc('all') }}</option> -->
    <option key="Valid" value="00">
{{ $tc('valid') }}
</option>
    <option key="Invalid" value="99">
{{ $tc('invalid') }}
</option>
  </b-select>
</template>

<script>
export default {
  props: {
    value: { type: String },
  },
  methods: {
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected)
        this.$emit('set-selected', selected || {})
      }
    },
  },
}
</script>
