<template>
  <multiselect
    :value="value"
    :options="options.map(item => item.code)"
    :multiple="true"
    :placeholder="`${$tc('select')} ${$tc(
      'responsibilitiesFiscal',
      2
    )} ${'...'}`"
    :custom-label="
      option =>
        options.find(item => item.code == option)
          ? options.find(item => item.code == option).name
          : ''
    "
    @select="onSelect"
    @remove="onRemove"
  >
    <template slot="selection" slot-scope="{ values, search, isOpen }">
      <span v-if="values.length &amp;&amp; isOpen" class="multiselect__single">
        {{ values.length }} {{ $tc('itemsSelected') }}
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title"
          >({{
            options.find(item => item.code == props.option)
              ? options.find(item => item.code == props.option).code
              : ''
          }})</span
        >
        <span class="option__small">
          &nbsp;{{
            options.find(item => item.code == props.option)
              ? options.find(item => item.code == props.option).name
              : ''
          }}</span
        >
      </div>
    </template>
  </multiselect>
</template>
<script>
import data from '@/assets/js/data/responsibilities'

export default {
  components: {
    multiselect: () => import('vue-multiselect')
  },
  props: {
    value: { type: Array }
  },
  data() {
    return {
      options: data
    }
  },
  methods: {
    onSelect(value) {
      const _this = this
      _this.$emit('input', _this.value.concat(value))
    },
    onRemove(value) {
      const _this = this
      const newArray = [..._this.value]
      const index = newArray.indexOf(value)
      newArray.splice(index, 1)
      _this.$emit('input', newArray)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
