<template>
  <el-dialog ref="log" :visible.sync="show">
    <div slot="title">
Log <i class="fas fa-paw"></i>
</div>
    <el-table v-loading="loadingLog" :data="logs" stripe>
      <el-table-column :label="$tc('action', 1)">
        <template slot-scope="scope">
          {{ $t(scope.row.logActionLabel) }}
        </template>
      </el-table-column>
      <el-table-column property="userName" :label="$tc('entity.user', 1)"></el-table-column>
      <el-table-column :label="$t('date')">
        <template slot-scope="scope">
          {{ scope.row.logTimestamp|formatToDateTime }}
        </template>
      </el-table-column>
      <el-table-column property="logDescription" :label="$t('description')"></el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import { responseException } from '@/assets/js/nbxr'

export default {
  props: {
  },
  data() {
    return {
      logs: [],
      id: '',
      entity: '',
      show: false,
      loadingLog: false,
    }
  },
  watch: {
    id() {
      const _this = this
      if (_this.entity && _this.id) {
        _this.logs = []
        _this.loadingLog = true
        _this.$http.get(`logs/?entity=${_this.entity}&id=${_this.id}`).then(response => {
          _this.loadingLog = false
          _this.logs = response.body
        }, response => {
          _this.loadingLog = false
          _this.$message.error(responseException(response))
        })
      }
    },
  },
}
</script>
