import { latinize } from '@/filters'
import { buildQueryString } from '@/filters/helpers'
import { i18n } from '@/i18n'

export function model() {
  return {
    selected: '',
    data: [],
    loading: false,
    query: {
      page: 1,
      limit: 10,
      searchTerm: '',
      total: '',
      offset: 0
    }
  }
}

export default function (controller, label, config) {
  config = config || {}

  return {
    props: {
      clearable: { required: false, default: false },
      disabled: { type: Boolean, default: false, required: false },
      labelAll: { default: true, required: false, type: Boolean },
      value: { required: false }
    },
    data() {
      return config.model || model()
    },
    methods: {
      onChange(value) {
        const _this = this
        if (_this.data && _this.data.length > 0) {
          _this.$emit(
            'set-selected',
            _this.data.find(e => e[config.attributeValue || 'id'] === value) ||
              {}
          )
        }
        _this.$emit('change', value)
        _this.$emit('input', value)
      },
      onLocalSearch(query) {
        const _this = this
        if (query !== '' && query.length > 3) {
          _this.data = config.data.filter(item =>
            latinize(item[config.attributeLabel]).includes(latinize(query))
          )
        } else {
          _this.data = []
        }
      },
      dispatchSetSelectedEntity() {
        const _this = this
        if (_this.data.length) {
          const selected = _this.data.find(
            e => e[config.attributeValue || 'id'] === _this.value
          )
          if (selected) {
            _this.$emit('set-selected-entity', selected)
          }
        }
      },
      get(action, params) {
        const _this = this
        params = params || {}

        _this.loading = true

        let uri = `${controller}/`
        if (action) {
          uri += `${action}`
        }

        const queryString = buildQueryString({
          ...(params.queryStringParams || {}),
          ...(_this.query && {
            page: _this.query.page,
            limit: _this.query.limit,
            searchTerm: _this.query.searchTerm
          })
        })

        return _this.$http
          .get(`${params.uri || uri}?${queryString}`)
          .then(response => {
            if (
              response.data &&
              response.data.data &&
              response.data.data.length > 0
            ) {
              _this.data = response.data.data
            } else if (
              response.data &&
              response.data.data &&
              response.data.data.length < 1
            ) {
              _this.data = []
            } else {
              _this.data = response.data
            }
            _this.dispatchSetSelectedEntity()

            return _this.data
          })
          .finally(() => (_this.loading = false))
      },
      setLocalInitValue(value) {
        const _this = this
        if (
          value &&
          !_this.data.find(item => item[config.attributeValue] === value)
        ) {
          _this.data = [
            config.data.find(item => item[config.attributeValue] === value)
          ]
        }
      },
      setRemoteInitValue(action) {
        const _this = this

        if (
          _this.value &&
          !_this.data.find(
            item => item[config.attributeValue || 'id'] === _this.value
          )
        ) {
          _this.loading = true

          let uri = `${controller}/`
          if (action) {
            uri += `${action}`
          }

          return _this.$http
            .get(uri)
            .then(response => {
              _this.data = [response.data]
              _this.$emit('set-selected-init', response.data)

              return _this.data
            })
            .finally(() => (_this.loading = false))
        }
      }
    },
    computed: {
      label: _this =>
        `${i18n.tc(label)}${_this.labelAll ? ` / ${i18n.tc('all', 1)}` : ''}`
    }
  }
}
