<template>
  <b-select :value="value" :placeholder="`[---${$tc('mode')}---]`" expanded="" @input="onSelect">
    <option key="Production" value="production">
{{ $tc('production') }}
</option>
    <option key="Habilitation" value="habilitation">
{{ $tc('habilitation') }}
</option>
    <option key="Test" value="test">
{{ $tc('test') }}
</option>
  </b-select>
</template>
<script>
export default {
  props: {
    value: { type: String },
  },
  methods: {
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected)
        this.$emit('set-selected', selected || {})
      }
    },
  },
}
</script>
