<template>
  <el-select :value="value" placeholder="..." value-key="id" :clearable="clearable" :disabled="disabled" @change="onChange">
    <el-option v-for="item in options" :key="item.id" :label="item.softwareName" :value="item"></el-option>
  </el-select>
</template>
<script>
import { responseException } from '@/assets/js/nbxr'

export default {
  props: {
    value: {},
    disabled: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
  },
  data() {
    return {
      options: [],
    }
  },
  mounted() {
    const _this = this
    _this.loading = true
    _this.$http.get('softwares').then(
      response => {
        _this.loading = false
        _this.options = response.body
        if (_this.value) {
          const selectedObject = _this.options.find(
            item => item.id === _this.value,
          )
          _this.onChange(selectedObject)
        } else {
          // _this.onChange('')
        }
      },
      response => {
        _this.$message.error(`Oops, ${responseException(response)}`)
      },
    )
  },
  methods: {
    onChange(value) {
      console.log('onChange', value)
      const _this = this
      if (value) {
        _this.$emit('input', value)
      }
    },
  },
}
</script>
