/* eslint-disable no-console */
import {
  DialogProgrammatic as Dialog,
  NotificationProgrammatic as Notification,
} from 'buefy'
import { register } from 'register-service-worker'
import { i18n } from './i18n'

if (process.env.NODE_ENV === 'production') {
  let isPreviouslyInstalled = false
  navigator.serviceWorker.getRegistrations().then(registrations => {
    isPreviouslyInstalled =
      registrations.filter(item => item.active.scriptURL.includes('service-worker.js'),
      ).length > 0
  })
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        '%c⧭',
        'color: #00bf00',
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
      )
    },
    registered() {
      console.log(
        '%c%s',
        'color: #aa00ff',
        'Service worker has been registered.',
      )
    },
    cached() {
      console.log(
        '%c⧭',
        'color: #0088cc',
        'Content has been cached for offline use.',
      )
    },
    updatefound() {
      console.log('%c%s', 'color: #733d00', 'New content is downloading.')
      if (isPreviouslyInstalled) {
        Notification.open({
          message: i18n.tc('appNewContent'),
          duration: 10000,
        })
      }
    },
    reload() {
      console.log('%c⧭', 'color: #f200e2', 'reloading..')
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          registration.unregister()
        }
      })
      Notification.open({
        message: i18n.tc('appReloading'),
      })
      setTimeout(() => {
        window.location.reload(true)
      }, 4000)
    },
    updated() {
      console.log(
        '%c%s',
        'color: #00e600',
        'New content is available: Please refresh.',
      )
      const _this = this

      if (isPreviouslyInstalled) {
        const timeoutId = setTimeout(() => {
          _this.reload()
        }, 180000)

        Dialog.confirm({
          cancelText: 'Recargar en 3 minutos',
          confirmText: 'Confirmar',
          message: i18n.tc('appNewReleaseMessage'),
          onConfirm: () => {
            clearTimeout(timeoutId)
            _this.reload()
          },
          title: i18n.tc('appNewRelease'),
          type: 'is-success',
        })
      }
    },
    offline() {
      console.log(
        '%c%s',
        'color: #00bf00',
        'No internet connection found. App is running in offline mode.',
      )
    },
    error(error) {
      console.log(
        '%c%s %s',
        'color: #0088cc',
        'Error during service worker registration:',
        error,
      )
    },
  })
}
