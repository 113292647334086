export function formatToDecimal(value) {
  if (value) {
    return value.toLocaleString(culture)
  }
}
export function formatToPercent(value) {
  if (value) {
    return (parseFloat(value) / 100).toLocaleString(culture, {
      style: 'percent',
      minimumFractionDigits: 2,
    })
  }

  return (0).toLocaleString(culture, {
    style: 'percent',
    minimumFractionDigits: 2,
  })
}
