<template>
  <el-button class="hidden-sm-and-down" size="mini" style="float: right; margin-left: 8px;" type="success" :to-url="toUrl" @click="onClick">
    <i class="fas fa-question"></i>
    <span class="hidden-sm-and-down">&nbsp;Tutorial</span>
    </el-button>
</template>
<script>
export default {
  props: {
    toUrl: String,
  },
  methods: {
    onClick(event) {
      if (this.toUrl) {
        window.open(this.toUrl, '_blank')

        // router.push({ name: this.toName, params: this.toParams })
      } else {
        this.$emit('set-click', event)
      }
    },
  },
}
</script>
