const monthSpanish = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
]

export function monthEs(month) {
  return monthSpanish[month]
}
export function calculatePage(array, pageSize, pageNumber) {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}
export function responseException(response) {
  let message = ''
  if (response) {
    if (response.status === 0) {
      message =
                'La API no responde, el equipo de soporte ya fué notificado, sin embargo intente más tarde!'

      return message
    }

    /* EL 401 SE CAPTURA EN EL MAIN...INTERCEPTOR */
    if (
      response.status === 401 &&
            response.body &&
            response.body.includes('Lifetime validation failed')
    ) {
      return false
    }

    if (response && response.body && response.body.exceptionMessage) {
      console.log('9')
      message = response.body.exceptionMessage
    } else if (response && response.body && response.body.Message) {
      console.log('1')
      message = response.body.Message
    } else if (response && response.body && response.body.error) {
      console.log('2')
      message = response.body.error
      if (response.body.error.message) {
        console.log('22')
        message = response.body.error.message
      }
    } else if (response && response.message) {
      console.log('3')
      message = response.message
    } else if (response && response.body && !response.bodyText) {
      console.log('4')
      message = response.body
    } else if (response.body && response.bodyText) {
      console.log('44')
      message = response.bodyText
      if (response.body.message) {
        console.log('444')
        message = response.body.message
      }
    } else if (
      response &&
            response.responseText &&
            response.responseText.indexOf('html') === -1
    ) {
      console.log('5')
      message = response.responseText
    } else if (response && response.statusText) {
      message = response.statusText
      console.log('6')

      // } else if (response) {
      //   message = response
    } else {
      console.log('7')
      message =
                'Ha ocurrido un error un inesperado, el equipo de soporte ya fue notificado!'
    }
  } else {
    message =
            'Ha ocurrido un error un inesperado, el equipo de soporte ya fue notificado!'
    console.log('8')
  }

  if (typeof message !== 'string') {
    message = ''
  }

  return message
}

export function calcularDv(nit) {
  // console.log(nit.length)
  if (isNaN(nit)) {
    // window.alert('Ingrese un número sin puntos, ni comas, ni guiones, ni espacios')
    return ''
  }

  // if (nit.length === 9) {
  let arreglo
  let dv
  arreglo = new Array(16)
  arreglo[1] = 3
  arreglo[2] = 7
  arreglo[3] = 13
  arreglo[4] = 17
  arreglo[5] = 19
  arreglo[6] = 23
  arreglo[7] = 29
  arreglo[8] = 37
  arreglo[9] = 41
  arreglo[10] = 43
  arreglo[11] = 47
  arreglo[12] = 53
  arreglo[13] = 59
  arreglo[14] = 67
  arreglo[15] = 71

  let x = 0
  let y = 0
  for (let i = 0; i < nit.length; i++) {
    y = nit.substr(i, 1)
    x += y * arreglo[nit.length - i]
  }
  y = x % 11
  if (y > 1) {
    dv = 11 - y
  } else {
    dv = y
  }

  return dv
}

export function now() {
  return new Date().toLocaleDateString('eu-ES')
}
