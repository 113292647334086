<template>
    <el-date-picker
        v-model="vModel"
        type="date"
        value-format="yyyy/MM/dd"
        :value="value"
        :readonly="readonly"
        :placeholder="placeholder"
        :format="format"
        :disabled="disabled"
        :clearable="clearable"
    ></el-date-picker>
</template>
<script>
export default {
  props: {
    disabled: Boolean,
    clearable: Boolean,
    readonly: Boolean,
    placeholder: String,
    value: String,
  },
  data() {
    return {
      vModel: new Date(),
      format: this.$store.getters.tokenApp.tokenData.companyDateFormat
        .replace('YYYY', 'yyyy')
        .replace('DD', 'dd'),
    }
  },
  watch: {
    vModel(val) {
      if (val) {
        this.$emit('input', val)
        this.$emit('set-date', val)
      }
    },
    value(val) {
      if (val) {
        this.vModel = val
        this.$emit('input', val)
        this.$emit('set-date', val)
      } else {
        this.vModel = val
        this.$emit('input', val)
      }
    },
  },
}
</script>
