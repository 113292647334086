<template>
  <b-select :value="value" :placeholder="`${$tc('select')} ${$tc('action')}`" expanded="" @input="onSelect">
    <option key="Create" value="create">
{{ $tc('create') }}
</option>
    <option key="Update" value="update">
{{ $tc('update') }}
</option>
  </b-select>
</template>
<script>
export default {
  props: {
    value: { type: String },
  },
  methods: {
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected)
        this.$emit('set-selected', selected || {})
      }
    },
  },
}
</script>
