export default () => ({
  id: 0,
  serviceId: '',
  serviceName: '',
  planName: '',
  planMode: 'Periodicidad',
  planDescription: '',
  planQuantity: '',
  planPriceYearly: '',
  planPriceMonthly: '',
  planPriceQuarterly: '',
  planPriceSemiannual: '',
  promotionYearly: [],
  promotionMonthly: [],
  promotionQuarterly: [],
  promotionSemiannual: [],
  planTaxPercentIncrease: '',
  planTaxPercentIncluded: '',
  planOnSite: false,
  planIsPackage: false,
  planIsDefault: false,
  planEnabled: true,
  planOrder: '',
  features: [],

  // #region /*----------   custom  ----------*/
  planAmountTotal: 0,

  // #endregion
  reset() {
    const _this = this || {}
    _this.id = 0
    _this.serviceId = ''
    _this.serviceName = ''
    _this.planName = ''
    _this.planDescription = ''
    _this.planQuantity = ''
    _this.planPriceYearly = ''
    _this.planPriceMonthly = ''
    _this.planPriceuarterly = ''
    _this.planPriceSemiannual = ''
    _this.promotionYearly = []
    _this.promotionMonthly = []
    _this.promotionQuarterly = []
    _this.promotionSemiannual = []
    _this.planTaxPercentIncrease = ''
    _this.planTaxPercentIncluded = ''
    _this.planOnSite = false
    _this.planIsPackage = false
    _this.planIsDefault = false
    _this.planEnabled = true
    _this.planOrder = ''
    _this.features = []
  },
})

export const promotion = () => ({
  promotionType: 'FirstPeriods',
  promotionPeriodsQuantity: 0,
  promotionDiscountPercent: 0,
})
