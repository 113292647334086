<template>
  <el-select v-model="userSelected" :placeholder="$tc('entity.user', 1)" :clearable="clearable" @change="selectUser">
    <el-option v-for="user in users" :key="user.id" :label="user.userEmail + ' (' + user.userFirstName + ')'" :value="user.id">
    </el-option>
  </el-select>
</template>
<script>
import { responseException } from '@/assets/js/nbxr'

export default {
  props: ['value', 'clearable'],
  data() {
    return {
      userSelected: '',
      users: [],
      loading: false,
    }
  },
  mounted() {
    const _this = this
    _this.$http.get('users').then(response => {
      _this.users = response.body
      _this.loading = false
    }, response => {
      _this.$message.error(responseException(response))
      _this.loading = false
    })
  },
  methods: {
    selectUser() {
      const _this = this
      _this.$emit('set-user', _this.users.find(e => e.id === _this.userSelected))
      _this.$emit('input', _this.userSelected)
    },
  },
}
</script>
