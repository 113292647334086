<template>
    <b-select
        :value="value"
        :expanded="true"
        :placeholder="`${$tc('select')} ${$tc('month', 2)}`"
        @input="onSelect"
    >
        <option
            v-for="option in options"
            :key="option.key"
            :value="option.key"
        >
            {{ option.value }}
        </option>
    </b-select>
</template>
<script>
import { toPascalCase } from '@/filters'
import { getMonthName } from '@/filters/helpers'
import { Info } from 'luxon'

export default {
  props: {
    expanded: { type: Boolean, default: true },
    value: { type: Number },
  },
  computed: {
    options: {
      get(_this) {
        return Info.months('numeric', { locale: 'es' }).map(item => ({
          key: +item,
          value: toPascalCase(getMonthName(item)),
        }))
      },
    },
  },
  methods: {
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected)
        this.$emit('set-selected', selected || {})
      }
    },
  },
}
</script>
