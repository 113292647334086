<template>
  <el-button size="mini" style="float: right; margin-left: 10px;" type="info" :disabled="disabled" :to-name="toName" :to-params="toParams" @click="onClick">
<i class="fas fa-upload"></i>&nbsp;{{ $t('upload') }}
</el-button>
</template>
<script>
import router from '@/router'

export default {
  props: {
    toName: String,
    toParams: Object,
    disabled: Boolean,
  },
  methods: {
    onClick(event) {
      if (this.toName) {
        router.push({ name: this.toName, params: this.toParams })
      } else {
        this.$emit('set-click', event)
      }
    },
  },
}
</script>
