<template>
  <el-button size="mini" style="float: right; margin-right: 12px;" type="text" :to-name="toName" :to-params="toParams" @click="onClick">
<span class="hidden-sm-and-down">&nbsp;{{ $t('cancel') }}</span>
</el-button>
</template>
<script>
import router from '@/router'

export default {
  props: {
    toName: String,
    toParams: Object,
    disabled: Boolean,
  },
  methods: {
    onClick(event) {
      if (this.toName) {
        router.push({ name: this.toName, params: this.toParams })
      } else {
        this.$emit('set-click', event)
      }
    },
  },
}
</script>
