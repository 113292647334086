<template>
  <el-select :value="value" placeholder="..." @change="onChange">
    <el-option v-for="item in options" :key="item.id" :label="$t(item.creditNoteKindName)" :value="item.id"></el-option>
  </el-select>
</template>
<script>
import { responseException } from '@/assets/js/nbxr'

export default {
  props: {
    value: String,
  },
  data() {
    return {
      options: [],
    }
  },
  mounted() {
    const _this = this
    _this.$http.get('creditNoteKinds').then(response => {
      _this.options = response.body
    }, response => {
      _this.$message.error(responseException(response))
    })
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
