<template>
  <b-autocomplete
    ref="select"
    :value="selected.name"
    :data="options"
    :open-on-focus="true"
    :placeholder="`${$tc('select')} ${$tc('city')}...`"
    field="name"
    @select="onSelect"
    @typing="onSearch"
  >
    <template slot="empty">
      {{ $tc('noResult') }}
    </template>
  </b-autocomplete>
</template>
<script>
import options from '@/assets/js/data/CO/cities'

export default {
  props: {
    value: { type: String },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      options,
    }
  },
  computed: {
    selected: {
      get(_this) {
        if (_this.value && _this.$refs.select) {
          _this.$refs.select.setSelected(
            _this.options.find(item => item.code === _this.value),
          )
        }

        return _this.value || { name: '' }
      },
    },
  },
  mounted() {
    const _this = this
    if (_this.value && _this.$refs.select) {
      _this.$refs.select.setSelected(
        _this.options.find(item => item.code === _this.value),
      )
    }
  },
  methods: {
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected.code)
        this.$emit('set-selected', selected)
      }
    },
    onSearch(value) {
      const _this = this
      if (value) {
        _this.options = options.filter(item => item.name.toLowerCase().includes(value),
        )
      } else {
        _this.options = options
      }
    },
  },
}
</script>
