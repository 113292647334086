<template>
  <b-select
    :value="value"
    :placeholder="`${$tc('select')} ${$tc('rol', 2)}`"
    expanded=""
    disabled="disabled"
    @input="onSelect"
  >
    <option :value="12">
      {{ $tc('Administrator') }}
    </option>
  </b-select>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Number }
  },
  data() {
    return {
      options: [],
      loading: false
    }
  },
  methods: {
    onSelect(value) {
      const _this = this
      _this.$emit('set-option', value)
      _this.$emit('input', value)
    },
    getByCompany() {
      const _this = this
      if (_this.companySelected) {
        _this.loading = true
        _this.$http
          .get(
            `${process.env.VUE_APP_API_HUB}roles/${_this.companySelected.id}/7`
          )
          .then(response => {
            if (response.body) {
              _this.options = response.body
              const selected = _this.options.find(item => item.roleIsAdmin)
              _this.$emit('input', selected.id)
            }
          })
          .finally(() => (_this.loading = false))
      }
    }
  },
  computed: {
    ...mapState(['companySelected']),
    headers: {
      get: _this => {
        if (
          _this.$store.getters.companySelected &&
          _this.$store.getters.companySelected.companyServiceApiKey
        ) {
          return {
            'x-api-key':
              _this.$store.getters.companySelected.companyServiceApiKey
          }
        }
      }
    }
  },
  watch: {
    companySelected(newValue, oldValue) {
      this.getByCompany()
    }
  },
  created() {
    this.getByCompany()
  }
}
</script>
