import store from '@/store'

const { DateTime } = require('luxon')

const timeZone = store.getters.tokenApp.tokenData.timeZoneId || 'America/Bogota'

let dateFormat = store.getters.tokenApp.tokenData.companyDateFormat || 'dd/MM/yyyy'

/* ----------  Cambiando el formato de mayusculas a minusculas, el estandar es en minusculas y es de luxon  ----------  */
dateFormat = dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')

const timeFormat = store.getters.tokenApp.tokenData.companyTimeFormat || 'HH:mm'

export function formatToDate(value) {
  if (value && value !== '0001-01-01T00:00:00Z') {
    return DateTime.fromISO(value).toFormat(dateFormat)
  }

  return ''
}

export function formatToDateIso(value) {
  if (value && value !== '0001-01-01T00:00:00Z') {
    return DateTime.fromISO(value).toFormat('yyyy-MM-dd')
  }

  return ''
}

export function formatToDateTime(value) {
  if (value && value !== '0001-01-01T00:00:00Z') {
    return DateTime.fromISO(value, {
      zone: timeZone,
    }).toFormat(`${dateFormat} ${timeFormat}`)
  }

  return ''
}

export function formatToTime(value) {
  if (value && value !== '0001-01-01T00:00:00Z') {
    return value
  }

  return ''
}
