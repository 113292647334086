<template>
  <b-autocomplete
    ref="autocomplete"
    v-bind="$attrs"
    :custom-formatter="choice => choice.name"
    :data="choicesSearchResults"
    expanded
    :icon-right="clearable && (value || searchTerm) ? 'times-circle' : null"
    :icon-right-clickable="clearable"
    keep-first
    open-on-focus
    :placeholder="placeholder || $tc('city')"
    @icon-right-click="clearable && $nextTick(clear)"
    v-on="{
      ...$listeners,
      input: () => {},
      select: onSelect,
      typing: onTyping
    }"
  >
    <!-- #region Slot default -->
    <div slot-scope="props">
      <strong>{{ props.option.name }}</strong>
    </div>
    <!-- #endregion Slot default -->

    <!-- #region Slot empty -->
    <template v-if="searchTerm" #empty>
      {{ $tc('recordNotFound', 2) }}
    </template>
    <template v-else #empty>
      {{ $tc('searchPlaceholder') }}
    </template>
    <!-- #endregion Slot empty -->

    <!-- #region Heredar y sobrescribir slots y scopedSlots dinámicamente -->
    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />

    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="props">
      <slot v-bind="props" :name="name" />
    </template>
    <!-- #endregion Heredar y sobrescribir slots y scopedSlots dinámicamente -->
  </b-autocomplete>
</template>

<script>
import { citiesWithRegion, regions } from '@/assets/js/data/CO'

import { noDiacritics } from '@/utils'
import find from 'lodash/find'

export default {
  name: 'AutocompleteCitiesRegion',
  inheritAttrs: false,
  props: {
    clearable: Boolean,
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  },
  data() {
    const cities = JSON.parse(JSON.stringify(citiesWithRegion))

    return {
      cities,
      choicesSearchResults: cities,
      searchTerm: null,
      selectedChoice: null
    }
  },
  watch: {
    selectedChoice(selectedChoice) {
      if (this.value && !(this.$refs.autocomplete || {}).selected) {
        this.setSelected(selectedChoice || null)
      } else if (!this.value) {
        this.setSelected(null)
      }
    },
    value: {
      immediate: true,
      handler(value) {
        if (!value) {
          // eslint-disable-next-line no-multi-assign
          this.searchTerm = this.selectedChoice = null

          return
        }

        this.selectedChoice = this.cities.find(item => item.code === value)

        this.setSelected(this.selectedChoice)
      }
    }
  },
  methods: {
    clear() {
      this.searchTerm = null
      this.setSelected(null)
    },

    focus() {
      // eslint-disable-next-line prefer-rest-params
      this.$refs.autocomplete.focus(...arguments)
    },
    onSelect(city) {
      if (!city) {
        this.$emit('input', null)
        this.$emit('select', null)

        this.$emit('select-city-code', null)
        this.$emit('select-city-name', null)
        this.$emit('select-region-code', null)
        this.$emit('select-region-name', null)
        this.$emit('select-region-iso', null)
        this.$emit('select-country-code', null)
        this.$emit('select-country-code', null)

        return
      }

      this.searchTerm = null
      this.search()

      this.$emit('input', city.code)
      this.$emit('select', city)

      this.$emit('select-city-code', city.code)
      this.$emit('select-city-name', city.name)
      this.$emit('select-country-code', city.countryCode)

      const region = regions.find(item => item.code === city.regionCode)

      if (region) {
        this.$emit('select-region-code', city.regionCode)
        this.$emit('select-region-name', region.name)
        this.$emit('select-region-iso', region.iso)
      } else {
        this.$emit('select-region-code', '')
        this.$emit('select-region-name', '')
        this.$emit('select-region-iso', '')
      }
    },
    onTyping(searchTerm) {
      this.search(searchTerm)

      // Evita que se borre el texto del autocomplete al momento de hacer una búsqueda
      // eslint-disable-next-line no-unused-expressions
      this.selectedChoice &&
        this.$refs.autocomplete.$nextTick(() => {
          this.$refs.autocomplete.newValue = searchTerm
        })

      this.selectedChoice = null
    },
    search(searchTerm) {
      this.searchTerm = searchTerm || null

      if (!this.searchTerm) {
        this.choicesSearchResults = this.cities

        return
      }

      // eslint-disable-next-line no-param-reassign
      searchTerm = noDiacritics(this.searchTerm.toLowerCase())
      this.choicesSearchResults = [
        ...this.cities.filter(city =>
          noDiacritics(city.name).toLowerCase().startsWith(searchTerm)
        ),
        ...this.cities.filter(
          city =>
            !noDiacritics(city.name).toLowerCase().startsWith(searchTerm) &&
            noDiacritics(city.name).toLowerCase().indexOf(searchTerm) >= 0
        )
      ]
    },

    setSelected() {
      if (this.$refs.autocomplete) {
        this.$refs.autocomplete.setSelected(...arguments)
        if (!this.$refs.autocomplete.selected) {
          this.searchTerm = this.$refs.autocomplete.newValue = null
        }

        return
      }

      this.$nextTick(() => {
        if (!this.$refs.autocomplete) return

        this.searchTerm = this.$refs.autocomplete.newValue = null
        this.$refs.autocomplete.setSelected(...arguments)
      })
    }
  }
}
</script>
