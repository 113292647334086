<template>
    <div class="center">
        <b-field
            v-if="companyServiceModeBillParent.toLocaleLowerCase() === 'paqueteGlobal'.toLocaleLowerCase()"
        >
            <b-radio
                :name="`modeBilling-${serviceId}`"
                :value="value"
                native-value="PaqueteGlobal"
                @input="onChange"
            >
                {{ $tc('packageGlobal') }}
            </b-radio>
        </b-field>
        <b-field
            v-if="['demandaGlobal', 'demanda'].includes(companyServiceModeBillParent.toLocaleLowerCase())"
        >
            <b-radio
                :name="`modeBilling-${serviceId}`"
                :value="value"
                native-value="DemandaGlobal"
                @input="onChange"
            >
                {{ $tc('demandGlobal') }}
            </b-radio>
        </b-field>
        <!-- <b-field>
            <b-radio
                :name="`modeBilling-${serviceId}`"
                :value="value"
                @input="onChange"
                native-value="DemandaIndividual"
            >
                {{$tc('demandIndividual')}}
            </b-radio>
        </b-field> -->
    </div>
</template>
<script>
export default {
  props: {
    companyServiceModeBillParent: {
      type: String,
      required: true,
      default: 'demandaGlobal',
    },
    serviceId: {
      type: Number,
      required: true,
      default: 0,
    },
    value: {
      type: String,
      required: true,
      default: 'PaqueteIndividual',
    },
  },
  methods: {
    onChange(value) {
      const _this = this
      _this.$emit('change', value)
      _this.$emit('input', value)
    },
  },
}
</script>
