import { parseJwt } from '@/utils/jwt'
import { differenceInSeconds } from 'date-fns'

export const INITIAL_TOKEN_VALUE = {
  accessToken: '',
  tokenData: {},
  expiresIn: '',
}

export function setTokenHub(token) {
  localStorage.setItem('token::hub', token)
}
export function setTokenApp(token) {
  localStorage.setItem('token::app', token)
}

export function getHubToken() {
  const data = JSON.parse(localStorage.getItem('token::hub') || JSON.stringify(INITIAL_TOKEN_VALUE))
  if (data.accessToken) {
    const secondsExpired = differenceInSeconds(new Date(), new Date(data.expiresIn))
    if (secondsExpired > 0) {
      return {}
    }

    return {
      ...data,
      tokenData: parseJwt(data.accessToken),
    }
  }

  return data
}
export function getAppToken() {
  const data = JSON.parse(localStorage.getItem('token::app') || JSON.stringify(INITIAL_TOKEN_VALUE))
  if (data.accessToken) {
    return {
      ...data,
      tokenData: parseJwt(data.accessToken),
    }
  }

  return data
}

export function logout() {
  localStorage.clear()
}
