<template>
    <el-date-picker
        v-model="vModel"
        :value="value"
        :format="format"
        :disabled="disabled"
        :readonly="readonly"
        :clearable="clearable"
        :placeholder="placeholder"
        :range-separator="$t('to')"
        :start-placeholder="$t('startDate')"
        :end-placeholder="$t('endDate')"
        @change="onChange"
        :picker-options="pickerOptions"
        unlink-panels
        type="daterange"
        align="right"
        value-format="yyyy/MM/dd"
    >
    </el-date-picker>
</template>
<script>
export default {
  props: {
    disabled: Boolean,
    clearable: Boolean,
    readonly: Boolean,
    placeholder: String,
    value: Array,
  },
  data() {
    return {
      vModel: [new Date(), new Date()],
      format: this.$store.getters.tokenApp.tokenData.companyDateFormat
        .replace('YYYY', 'yyyy')
        .replace('DD', 'dd'),
      pickerOptions: {
        shortcuts: [
          {
            text: 'Today',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  watch: {
    value(val) {
      console.log('val')
      console.log(val)
      if (val) {
        this.vModel = val
        console.log('watch value---', val)
        this.$emit('input', val)
        this.$emit('set-range', val)
      }
    },
    vModel(val) {
      if (val) {
        console.log('watch vModel---', val)
        this.$emit('input', val)
        this.$emit('set-range', val)
      }
    },
  },
  methods: {
    onChange(value) {
      console.log('value---', value)
      this.$emit('input', value)
      this.$emit('set-range', value)
    },
  },
}
</script>
