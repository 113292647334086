export function getMonthName(idx) {
  const objDate = new Date()

  objDate.setDate(1)
  objDate.setMonth(idx - 1)

  return objDate.toLocaleString('es-co', {
    month: 'long',
  })
}
