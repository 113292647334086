<template>
  <el-button size="mini" type="primary" :loading="loading" :disabled="disabled" @click="onClick">
{{ $t('save') }}
</el-button>
</template>
<script>
export default {
  props: {
    disabled: Boolean,
    loading: Boolean,
  },
  methods: {
    onClick(event) {
      this.$emit('set-click', event)
    },
  },
}
</script>
