import { DialogProgrammatic as Dialog } from 'buefy'
import { formatToDateTime } from '@/filters'
import { i18n } from '@/i18n'

export const viewEmailLog = data => {
  let message = `
    <article class="message is-info">
      <div class="message-body">
       Importante: El log de correos sólo es almacenado durante 30 días.
      </div>
    </article>

    <table class="table"><thead>
      <tr>
        <td>${i18n.tc('event', 1)}</td>
        <td style="width:30em;">${i18n.tc('date', 1)}</td>
        <td>${i18n.tc('recipient', 1)}</td>
        <td></td>
      </tr>
    </thead><tbody>
  `

  data.forEach(item => {
    message += `<tr>
            <td>
              <span class="tag ${item.logEmailLevel === 'info' ? 'is-info' : 'warn'}">
              <i class="fad fa-${item.logEmailLevel === 'error' ? 'info' : 'exclamation'}">
              </i>
              &nbsp;
              ${i18n.tc(item.logEmailEvent)}
              </span>
            </td>
            <td style="width:30em;">${formatToDateTime(item.logEventTimestamp)}</td>
            <td>${item.logEmailRecipient}</td>
            <td>${item.logEmailDescription || ''}</td>
          </tr>`
  })

  message += '</tbody></table>'

  Dialog.alert({
    message,
    title: i18n.tc('log'),
  })
}
