<template>
    <iframe
        id="id_planSelect"
        ref="ifrPlanSelect"
        :src="uriPlanSelect"
        style="width:100%;border:none;"
    ></iframe>
</template>
<script>
import { mapGetters } from 'vuex'

import { bindEvent } from '@/filters/helpers'
import model from '@/views/plans/model'

export default {
  props: {
    modeBill: {
      type: String,
      default: 'PaqueteIndividual',
      required: true,
    },
    value: {
      default: 0,
      required: true,
    },
    plan: {
      type: Object,
      default: model(),
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
    this.listenWindow()
  },
  methods: {
    onChangeModeBill(value) {
      const _this = this
      _this.$emit('update:value', '')
      _this.$emit('update:plan', model())
      if (value === 'PaqueteGlobal') {
        _this.loading = true
      }
    },
    listenWindow() {
      const _this = this
      bindEvent(window, 'message', e => {
        if (
          e.data &&
          e.data.type === 'frame:height' &&
          e.data.source === 'plan:select-without-payment'
        ) {
          document.getElementById('id_planSelect').style.height = `${e.data
            .value + 70}px`
        } else if (e.data && e.data.type === 'selected:plan') {
          _this.$emit('input', e.data.value.id)
          _this.$emit('update:plan', e.data.value)
        } else if (e.data && e.data.type === 'loading:end') {
          _this.loading = false
        }
      })
    },
  },
  computed: {
    ...mapGetters(['tokenApp']),
    uriPlanSelect: _this => `${process.env.VUE_APP_URI_HUB}blank/plans/select/7/${
      _this.tokenApp.tokenData.cmpId
    }/${_this.mode}?v=${new Date().toLocaleDateString()}`,
    mode: _this => {
      console.log('%c⧭', 'color: #1d5673', _this.modeBill.toLowerCase())
      if (
        ['paqueteindividual', 'paqueteglobal'].includes(
          _this.modeBill.toLowerCase(),
        )
      ) {
        return 'paquete'
      } if (['demandaindividual'].includes(_this.modeBill.toLowerCase())) {
        return 'demanda'
      }
    },
  },
  watch: {
    modeBill(newValue) {
      if (newValue) {
        this.onChangeModeBill(newValue)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
iframe {
  -webkit-transition: width 400ms ease-in-out, height 400ms ease-in-out;
  -moz-transition: width 400ms ease-in-out, height 400ms ease-in-out;
  -o-transition: width 400ms ease-in-out, height 400ms ease-in-out;
  transition: width 400ms ease-in-out, height 400ms ease-in-out;
}
</style>
