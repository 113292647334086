<template>
    <b-select
        :expanded="expanded"
        :value="value"
        :placeholder="`${$tc('select')}...`"
        @input="onChange"
    >
        <option value="01">
IVA
</option>
        <option value="04">
INC
</option>
        <option value="ZA">
IVA e INC
</option>
        <option value="ZZ">
No aplica
</option>
    </b-select>
</template>
<script>
export default {
  name: 'SelectResponsibleFor',
  props: {
    expanded: { type: Boolean, default: true },
    value: { type: String },
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
}
</script>
