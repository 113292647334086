<template>
    <object class="pdfPreview" :style="{ height: documentHeight ? documentHeight : '1194px' }" :data="document" type="application/pdf"></object>
</template>
<script>
export default {
  name: 'PdfDocument',
  props: {
    document: {},
  },
  data() {
    return {
      pdf: '',
      documentHeight: '1194px',
    }
  },
}
</script>
<style>
.pdfPreview{
  width: 846px;
}
</style>
