import { ToastProgrammatic as Toast } from 'buefy'
import { i18n } from '@/i18n'

export default identificationNumber => {
  let vpri; let x; let y; let
    z

  // Se limpia el Nit
  identificationNumber = identificationNumber.replace(/\s/g, '') // Espacios
  identificationNumber = identificationNumber.replace(/,/g, '') // Comas
  identificationNumber = identificationNumber.replace(/\./g, '') // Puntos
  identificationNumber = identificationNumber.replace(/-/g, '') // Guiones

  // Se valida el nit
  if (isNaN(identificationNumber)) {
    Toast.open({
      message: i18n.tc('identificationIsNotNumber', 1, { value: identificationNumber }),
      type: 'is-danger',
    })

    return ''
  }

  // Procedimiento
  vpri = new Array(16)
  z = identificationNumber.length

  vpri[1] = 3
  vpri[2] = 7
  vpri[3] = 13
  vpri[4] = 17
  vpri[5] = 19
  vpri[6] = 23
  vpri[7] = 29
  vpri[8] = 37
  vpri[9] = 41
  vpri[10] = 43
  vpri[11] = 47
  vpri[12] = 53
  vpri[13] = 59
  vpri[14] = 67
  vpri[15] = 71

  x = 0
  y = 0
  for (let i = 0; i < z; i++) {
    y = identificationNumber.substr(i, 1)
    x += y * vpri[z - i]
  }

  y = x % 11

  return y > 1 ? 11 - y : y
}
