<template>
  <div>
    <b-select :value="value" :placeholder="label" @input="onChange">
      <option :label="$tc('yearly')" value="Yearly"></option>
      <option :label="$tc('monthly')" value="Monthly"></option>
    </b-select>
  </div>
</template>
<script>
import select from './common'

export default {
  extends: select('plans', 'frequencyBilling')
}
</script>
