import Vue from 'vue'

import Vuex from 'vuex'

import router from '@/router'
import { getAppToken, getHubToken, INITIAL_TOKEN_VALUE } from '@/utils/auth'
import { parseJwt } from '@/utils/jwt'

import { setTokenApp, setTokenHub } from '../utils/auth'

Vue.use(Vuex)
const state = {
  companies: [],
  companySelected: JSON.parse(
    localStorage.getItem('isv::company-selected') || '[]'
  ),
  tokenHub: getHubToken(),
  tokenApp: getAppToken(),
  routeFrom: '',
  routeTo: ''
}
const getters = {
  companies: stateApp => (stateApp.companies ? stateApp.companies : []),
  companySelected: stateApp => stateApp.companySelected,
  tokenHub: stateApp => stateApp.tokenHub,
  tokenApp: stateApp => stateApp.tokenApp,
  isAuthenticated: stateApp =>
    stateApp.tokenHub &&
    stateApp.tokenHub.tokenData &&
    stateApp.tokenHub.tokenData.usrId &&
    stateApp.tokenApp.tokenData.cmpKey > 0 &&
    new Date(stateApp.tokenHub.expiresIn) > Date.now() &&
    new Date(stateApp.tokenApp.expiresIn) > Date.now()
}
const mutations = {
  SET_COMPANIES(stateApp, data) {
    if (data.length > 0) {
      stateApp.companies = [...stateApp.companies, ...data]
    } else {
      stateApp.companies = []
    }
  },
  SET_COMPANY_SELECTED(stateApp, data) {
    stateApp.companySelected = data
    localStorage.setItem('isv::company-selected', JSON.stringify(data))
  },
  SET_TOKEN_HUB(stateApp, data) {
    stateApp.tokenHub = {
      accessToken: data.accessToken,
      expiresIn: data.expiresIn,
      tokenData: data.accessToken ? parseJwt(data.accessToken) : ''
    }
    setTokenHub(
      JSON.stringify({
        accessToken: data.accessToken,
        expiresIn: data.expiresIn
      })
    )
  },
  SET_TOKEN_APP(stateApp, data) {
    stateApp.tokenApp = {
      accessToken: data.accessToken,
      expiresIn: data.expiresIn,
      tokenData: data.accessToken ? parseJwt(data.accessToken) : ''
    }
    setTokenApp(
      JSON.stringify({
        accessToken: data.accessToken,
        expiresIn: data.expiresIn
      })
    )
  },
  SET_FROM(stateApp, from) {
    stateApp.routeFrom = from
  },
  SET_TO(stateApp, to) {
    stateApp.routeTo = to
  },
  SET_LOGOUT(stateApp) {
    localStorage.clear()
    Object.assign(stateApp, {
      companies: [],
      companySelected: JSON.parse(
        localStorage.getItem('isv::company-selected') || '[]'
      ),
      routeFrom: '',
      routeTo: ''
    })
    if (router.currentRoute.name !== 'login') {
      router.push({
        name: 'login'
      })
    }
  }
}
const actions = {
  setCompanies({ commit }, data) {
    commit('SET_COMPANIES', data)
  },
  setCompanySelected({ commit }, data) {
    commit('SET_COMPANY_SELECTED', data || {})
  },
  setTokenHub({ commit }, data) {
    commit('SET_TOKEN_HUB', data)
  },
  setTokenApp({ commit }, data) {
    commit('SET_TOKEN_APP', data)
  },
  setLogout({ commit }) {
    commit('SET_TOKEN_HUB', INITIAL_TOKEN_VALUE)
    commit('SET_TOKEN_APP', INITIAL_TOKEN_VALUE)
    commit('SET_LOGOUT', INITIAL_TOKEN_VALUE)
  },
  setFrom({ commit }, from) {
    commit('SET_FROM', from)
  },
  setTo({ commit }, to) {
    commit('SET_TO', to)
  }
}
const store = new Vuex.Store({
  actions,
  getters,
  mutations,
  state,
  strict: process.env.NODE_ENV !== 'production' // plugins: [vuexPersist.plugin]
})
export default store
