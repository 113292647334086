<template>
  <el-button size="mini" style="float: right;" type="success" :disabled="disabled" :to-name="toName" :to-params="toParams" @click="onClick">
    <i class="fas fa-plus"></i><span class="hidden-sm-and-down">&nbsp;{{ $t('save') }}</span>
  </el-button>
</template>
<script>
import router from '@/router'

export default {
  props: {
    toName: String,
    toParams: Object,
    disabled: Boolean,
  },
  mounted() {
    console.log(this)
  },
  methods: {
    onClick(event) {
      if (this.toName) {
        router.push({ name: this.toName, params: this.toParams })
      } else {
        this.$emit('set-click', event)
      }
    },
  },
}
</script>
