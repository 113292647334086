<template>
  <el-button
    size="mini"
    type="success"
    :disabled="disabled"
    style="float: right;"
    @click="onClick"
>
    <i class="fas fa-arrow-to-left"></i>
    &nbsp;{{ $t('use') }}
  </el-button>
</template>
<script>
export default {
  name: 'ButtonGridUse',
  props: {
    disabled: Boolean,
  },
  methods: {
    onClick(event) {
      this.$emit('set-click', event)
    },
  },
}
</script>
