<template>
    <b-select
        :value="value"
        :placeholder="`${$tc('select')} ${$tc('year')}`"
        :expanded="expanded"
        @input="onSelect($event)"
    >
        <option
            v-for="option in options"
            :key="option"
            :value="option"
        >
            {{ option }}
        </option>
    </b-select>
</template>
<script>
export default {
  props: {
    expanded: { type: Boolean, default: true },
    value: { type: Number },
  },
  computed: {
    options: {
      get(_this) {
        const options = []
        for (let index = 2019; index < new Date().getFullYear() + 1; index++) {
          options.push(index)
        }

        return options
      },
    },
  },
  methods: {
    onSelect(selected) {
      if (selected) {
        this.$emit('input', selected)
        this.$emit('set-selected', selected || {})
      }
    },
  },
}
</script>
