import store from '@/store'

const currency = 'COP'

const culture = store.getters.tokenApp.tokenData.cultureId || 'es-CO'

export function getCurrencyFormatWithSymbol(currencyCode) {
  return {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
  }
}

export function getCurrencyFormatWithIsoCode(currencyCode) {
  return {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'code',
  }
}

export function getCurrencyFormatWithLocalName(currencyCode) {
  return {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'name',
  }
}

export function getCurrencyFormatNumbersOnly(currencyCode) {
  return {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'none',
  }
}

export function formatToCurrencyWithIsoCode(value, code, lang) {
  // eslint-disable-next-line no-redeclare
  var code = code || 'COP'
  // eslint-disable-next-line no-redeclare
  var lang = lang || 'es-CO'

  const currencyFormat = getCurrencyFormatWithIsoCode(code)

  const stripSymbols = currencyFormat.currencyDisplay === 'none'
  const localFormat = stripSymbols
    ? {
      ...currencyFormat,
      currencyDisplay: 'code',
    }
    : currencyFormat

  let result = Intl.NumberFormat(lang, localFormat).format(value)

  if (stripSymbols) {
    result = result.replace(/[a-z]{3}/i, '').trim()
  }

  return result
}

export function formatToCurrency(value) {
  if (value) {
    return parseFloat(value).toLocaleString(culture, {
      currencyDisplay: 'symbol',
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    })
  }

  return (0).toLocaleString(culture, {
    currencyDisplay: 'symbol',
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  })
}

export function formatToMoney(value) {
  // if (value) {
  //   return value ? '$ ' + value.toLocaleString(culture, { minimumFractionDigits: 2 }) : '$ 0'
  // }
  // return '$ ' + 0
  return formatToCurrency(value)
}
