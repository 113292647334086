/* eslint-disable import/prefer-default-export */
/**
 * Recibe un texto y lo retorna sin los diacríticos (ej: ú, ü, ó, ö, ñ)
 *
 * @param {String} text
 * @returns {String}
 */
export function noDiacritics(text) {
  if (text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  return text
}
