import 'buefy/dist/buefy.css'
import './components'
import './registerServiceWorker'

import Vue from 'vue'

import Buefy, { ToastProgrammatic as Toast } from 'buefy'
import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'
import isURL from 'validator/lib/isURL'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  confirmed, email, max, required
} from 'vee-validate/dist/rules'
import loading from 'vue-full-loading'
import VueResource from 'vue-resource'
import Vuex from 'vuex'

import router from '@/router'
import store from '@/store'

import App from './App'
import { responseException } from './assets/js/nbxr'
import * as filters from './filters'
import { i18n } from './i18n'

/* ----------  filters  ----------*/

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

/* ----------  prototipes  ----------*/

Vue.prototype.$uuid = uuid
Vue.prototype.$luxon = DateTime

/* ----------  vue  ----------*/

Vue.use(VueResource)
Vue.use(Vuex)
Vue.use(loading)
Vue.use(Buefy, {
  defaultIconPack: 'fad'
})

/* ----------  vee-valdidate  ----------*/

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

const urlFixRule = value =>
  isURL(value, {
    require_tld: false
  })

extend('url', urlFixRule)
extend('email', {
  ...email,

  // rule definition
  message() {
    return i18n.t('fieldIsEmail')
  }
})
extend('confirmed', {
  ...confirmed,

  // rule definition
  message() {
    return i18n.t('fieldIsConfirmed')
  }
})
extend('required', {
  ...required,

  // rule definition
  message() {
    return i18n.t('required')
  }
})
extend('max', {
  ...max,

  // rule definition
  message() {
    return i18n.t('max')
  }
})

/* ----------  common headers  ----------*/

Vue.http.headers['Content-Type'] = 'application/json; charset=utf-8'

/* ----------  http interceptors  ----------*/

Vue.http.interceptors.push(
  request =>
    function (response) {
      if (response.status === 500) {
        if (response.data.Message) {
          Toast.open({
            message: `${i18n.tc('internalServerError')}: ${
              response.data.Message
            }`,
            type: 'is-danger',
            duration: 15000
          })
        } else {
          Toast.open({
            message: `${i18n.tc('internalServerError')} ${response.body}`,
            type: 'is-danger',
            duration: 15000
          })
        }
      } else if (
        response.status === 200 &&
        request.method === 'POST' &&
        !request.url.includes('auth')
      ) {
        Toast.open({
          message: 'Registro creado con exito',
          type: 'is-success'
        })
      } else if (response.status === 200 && request.method === 'PUT') {
        Toast.open({
          message: 'Registro editado con exito',
          type: 'is-success'
        })
      } else if (response.status === 200 && request.method === 'DELETE') {
        Toast.open({
          message: 'Registro eliminado con exito',
          type: 'is-success'
        })
      } else if (
        response.status === 200 &&
        request.method === 'PATCH' &&
        (request.headers.get('x-confirm-message') || 'true') === 'true'
      ) {
        Toast.open({
          message: 'Registro guardado con exito',
          type: 'is-success'
        })
      } else if (response.status === 400) {
        Toast.open({
          message: `${response.body.message}`,
          type: 'is-danger'
        })
      } else if (response.status === 401) {
        Toast.open({
          message: `${i18n.tc('requiredIsAuthenticated')} ${response.body}`,
          type: 'is-danger'
        })
        store.dispatch('setLogout')
      } else if (
        response.status === 403 &&
        response.body.includes('Token revocado')
      ) {
        Toast.open({
          message: `${i18n.tc('requiredIsAuthenticated')} ${response.body}`,
          type: 'is-danger'
        })
        store.dispatch('setLogout')
      } else if (response.status === 404) {
        Toast.open({
          message: `${i18n.tc('statusCode404')} ${response.body}`,
          type: 'is-danger'
        })
      } else if (response.status === 406) {
        Toast.open({
          message: `${i18n.tc('statusCode406')} ${response.body}`,
          type: 'is-danger'
        })
        store.dispatch('setLogout')
      } else if (response.status === 204) {
        Toast.open({
          message: `${i18n.tc('statusCode204')} ${response.body}`,
          type: 'is-warning'
        })
      }
    }
)
Vue.http.interceptors.push((request, next) => {
  request.emulateJSON = true

  if (request.url.includes(process.env.VUE_APP_API_HUB)) {
    request.emulateJSON = false
  }

  if (!request.headers.get('Accept')) {
    request.headers.set('Accept', 'application/json')
  }

  if (
    request.url.includes(process.env.VUE_APP_API_HUB) &&
    !request.headers.get('Authorization')
  ) {
    request.headers.set(
      'Authorization',
      `Bearer ${store.getters.tokenHub.accessToken}`
    )
  }

  if (
    [
      `${process.env.VUE_APP_API_OKI_DOCS}payrolls`,
      `${process.env.VUE_APP_API_OKI_DOCS}settings`
    ].some(item => request.url.includes(item)) &&
    !request.headers.get('x-api-key') &&
    store.getters.companySelected &&
    store.getters.companySelected.services
  ) {
    var companyService = store.getters.companySelected.services.find(
      item => item.serviceId === +process.env.VUE_APP_SERVICE_PYR_ISV_ID
    )
    request.headers.set('x-api-key', companyService.companyServiceApiKey)
  }

  if (
    request.url.includes(`${process.env.VUE_APP_API_ISV}`) &&
    !request.headers.get('x-api-key') &&
    store.getters.companySelected &&
    store.getters.companySelected.services
  ) {
    var companyService = store.getters.companySelected.services.find(
      item => item.serviceId === +process.env.VUE_APP_SERVICE_FE_ISV_ID
    )
    request.headers.set('x-api-key', companyService.companyServiceApiKey)
  }

  next()
})

/* #region  config
------------------------------------------------- */

Vue.config.productionTip = true

/* #endregion
-------------------------------------------------- */

/* eslint-disable no-new */

Vue.mixin({
  data() {
    return {
      feature: {},
      featureError: '',
      featureIsValid: true,
      featureMessage: '',
      featureValueCurrent: ''
    }
  },

  methods: {
    responseException
  }
})
new Vue({
  el: '#app',
  i18n,
  render: h => h(App),
  router,
  store
})
